.header {
  position: relative;
  height: 80px;
  color: $colorBlueCrayola;

  @media (max-width: $mobile) {
    height: 70px;
  }

  &__wrap {
    position: absolute;
    z-index: 50;
    left: 50%;
    top: 0;
    width: 100%;
    transform: translateX(-50%);
    background-color: $mainColor;
  }

  &__container {
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $mobile) {
      height: 70px;
      gap: 5px;
      background-color: rgba($color: $mainColor, $alpha: 0.5);
    }
  }

  &__logo {
    color: $colorLogo;
  }

  &__body {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 120px;
    justify-items: center;
  }

  // &__menu {
  // }

  &__child {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  // &__profile {
  // }

  &__toggle {
    position: relative;
  }
}

//==========================================================================================================================================

.logo {
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }

  &__text {
    transition: color 0.6s ease 0s;

    &:hover {
      color: $colorBlue;
    }
  }
}

//==========================================================================================================================================
.toggle {
  position: absolute;
  opacity: 0;
  visibility: hidden;

  @media (max-width: $mobile) {
    position: absolute;
    opacity: 1;
    visibility: visible;
    width: 24px;
    height: 19px;
    z-index: 55;
    right: 30px;

    span,
    &::before,
    &::after {
      content: "";
      transition: all 0.3s ease 0s;
      right: 0;
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: $colorCandyPink;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    span {
      top: calc(50% - 8%);
    }

    .open & {
      span {
        width: 0;
      }

      &::before {
        top: calc(50% - 8%);
        transform: rotate(-45deg);
      }

      &::after {
        bottom: calc(50% - 8%);
        transform: rotate(45deg);
      }
    }
  }
}

//==========================================================================================================================================

.scrolled {
  position: fixed;
  animation-name: fadeInUp;
  animation-duration: 1s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    top: -50px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

//==========================================================================================================================================

.child {
  @media (max-width: $mobile) {
    opacity: 0;
    visibility: hidden;
  }

  &__name {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    svg,
    img {
      transition: all 0.6s ease 0s;
      max-width: 15px;
      @media (min-width: $mobile) {
        max-width: 36px;
      }
    }
  }

  &__inner {
    display: flex;
    gap: 15px;

    justify-content: center;
    align-items: center;
    color: $colorPink;

    @media (max-width: $tablet) {
      & .circles {
        display: none;
      }
    }
  }

  &__score {
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile) {
      display: none;
    }

    .profile-child__link & {
      &.child__score-mobile {
        display: flex;
        color: $colorPink;
      }
    }
  }
}

//==========================================================================================================================================
.profile {
  position: relative;

  &:hover {
    @media (min-width: $mobile) {
      & .profile__list {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        top: 100%;
        right: 10px;
      }
    }

    & .profile__account .arrow {
      transform: rotate(180deg);

      path {
        fill: $colorPinkAmaranth;
      }
    }
  }

  &__account {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 0 3px;
    padding: 5px 0;

    svg {
      transition: transform 0.6s ease 0s;
    }

    span {
      text-transform: capitalize;
    }

    @media (max-width: $mobile) {
      opacity: 0;
    }
  }

  &__list {
    display: grid;
    justify-items: center;
    min-width: 185px;
    max-width: 300px;
    position: absolute;
    z-index: 5;
    padding: 14px 14px 20px 14px;
    background-color: $mainColor;
    border-radius: 17px;
    border: 0.3px solid $colorPinkAmaranth;
    box-shadow: 3px 4px 4px 0px $colorPinkAmaranth;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.6s ease 0s;
    right: -40%;

    @media (min-width: $mobile) {
      top: 100%;
      right: 10px;
    }
  }

  &__item {
    &:last-child {
      & .profile__link {
        padding: 2px 12px 4px 12px;
      }
    }

    &:hover {
      & .profile__link {
        color: $colorBlueCrayola;
      }
    }
  }

  &__link {
    display: block;
    color: $colorPinkAmaranth;
    transition: all 0.6s ease 0s;

    &:not(.button-border) {
      margin: 8px 0;
    }
  }

  &__avatar {
    padding-bottom: 12px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -7px;
      left: 0;
      z-index: -1;
      display: block;
      border-bottom: 0.5px solid $colorBlueCrayola;
    }
  }
}

//==========================================================================================================================================
.profile-child {
  position: relative;
  width: 100%;
  display: flex;
  gap: 3px;
  justify-items: start;
  align-items: center;

  &:not(.profile__avatar) {
    padding-bottom: 4px;
  }

  &:hover {
    .profile-child__link {
      color: $colorPinkAmaranth;
    }
  }

  &__link {
    width: 100%;
    max-width: calc(300px - 14px - 14px);
    display: flex;
    align-items: center;
    gap: 7px;
    transition: all 0.6s ease 0s;
  }
  svg,
  img {
    max-width: 24px;
    min-height: 24px;
  }
}

.child-name-in-menu {
  margin-right: auto;
}

//==========================================================================================================================================
