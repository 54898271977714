// ==================== GENERAL ============================================================

.label-form {
  color: #4d4d4d;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media (max-width: $mobileSmall) {
    font-size: 14px;
    max-width: 55px;
  }
}

.form-email {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 26px;

  @media (max-width: $mobileSmall) {
    padding-right: 40px;
  }
}

.input-form {
  width: 419px !important;
  height: 45px;
  text-align: center;
  font-weight: bold;
  border-radius: 14px;
  border: 1px solid #c0b2b2;
  background: #fff;
}

@media (max-width: $mobileSmall) {
  .input-form {
    width: 202px !important;
    font-size: 14px;
    height: 32px;
  }
}

.form-button-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 54px;
  background: #4cd0f9;
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.email-confirm__success-container,
.password-reset-from-key__container,
.verification-sent__container,
.password-reset__cont {
  display: flex;
  height: 100vh;
  gap: 40px;
}

.main-password-reset-from-key ~ .footer,
.main-verification-sent ~ .footer,
.main-email-confirm ~ .footer {
  background-color: #ffc1ee;
}

.password-reset-from-key-done__text,
.password-reset-done__text {
  color: #4d4d4d;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 22px */

  @media (max-width: $tabletNew) {
    font-size: 22px;
  }

  @media (max-width: $mobileSmall) {
    font-size: 14px;
  }
}

.password-reset-from-key-done__text-container,
.password-reset-done__text-container {
  width: 658px;
  display: flex;
}

.password-reset-from-key__right-block__text-wrap,
.verification-sent__text-wrap,
.email-confirm__text-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 482px;
  padding-top: 135px;
  color: #81d5ee;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
}

.lama-ok__wrap {
  position: relative;
  height: 426px;
  width: 600px;

  @media (max-width: $tabletNew) {
    height: 343px;
    width: 490px;
  }

  @media (max-width: $mobileSmall) {
    height: 178px;
    width: 260px;
  }

  .lama-ok__background {
    @media (max-width: $tabletNew) {
      width: 478px;
    }

    @media (max-width: $mobileSmall) {
      width: 248px;
    }
  }

  .lama-ok__lama-img {
    position: absolute;
    bottom: 0;
    left: 25%;

    @media (max-width: $tabletNew) {
      width: 242px;
    }

    @media (max-width: $mobileSmall) {
      width: 124px;
    }
  }
}

//==========================LOGOUT==========================================================================================================

.logout-page {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 100px;
    gap: 10px;
    @media (min-width: $mobile) {
      padding-bottom: 150px;
      gap: 25px;
    }
  }

  &__img {
    width: 100%;
    max-width: 216px;
    @media (min-width: $mobile) {
      max-width: 505px;
    }
    @media (min-width: $pcSmall) {
      max-width: 553px;
    }
  }

  &__wrap {
    display: grid;
    justify-items: center;
    gap: 21px;
    @media (min-width: $mobile) {
      gap: 40px;
    }
    @media (min-width: $pcSmall) {
      gap: 50px;
    }
  }

  &__text {
  }
}
