// ==================== EMAIL-CONFIRM ============================================================

.email-confirm__success-container {
  justify-content: center;

  @media (max-width: $tabletNew) {
    padding: 0 67px;
    padding-top: 15px;
    gap: 5px;
  }

  @media (max-width: $mobileSmall) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
  }

  .email-confirm__text-wrap {
    @media (max-width: $tabletNew) {
      padding-top: 27px;
      gap: 30px;
      width: 385px;
      line-height: 100%;
    }

    @media (max-width: $mobileSmall) {
      padding-top: 0px;
      gap: 10px;
      width: 220px;
      line-height: 100%;
    }

    .email-confirm__text {
      @media (max-width: $tabletNew) {
        font-size: 30px;
        line-height: 100%;
      }

      @media (max-width: $mobileSmall) {
        font-size: 16px;
        line-height: 100%;
      }
    }
  }

  .form-confirm-email {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 482px;

    @media (max-width: $tabletNew) {
      width: 385px;
      padding-top: 5px;
    }

    @media (max-width: $mobileSmall) {
      width: 220px;
      padding-top: 10px;
    }

    .form-button-confirm-email {
      display: flex;
      width: 203px;
      height: 70px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 54px;
      background: #4cd0f9;
      color: #fff;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;

      @media (max-width: $mobileSmall) {
        width: 125px;
        height: 33px;
        font-size: 16px;
      }
    }
  }

  .email-confirm__message-wrap {
    position: absolute;
    right: 0%;
    top: 21%;
    width: 175px;

    @media (max-width: $tabletNew) {
      width: 159px;
    }

    @media (max-width: $mobileSmall) {
      width: 100px;
    }
  }

  .email-confirm__message-msg {
    position: absolute;
    right: 6%;
    top: 25%;
    white-space: pre-line;
    color: #4d4d4d;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media (max-width: $tabletNew) {
      font-size: 20px;
      right: 7%;
    }

    @media (max-width: $mobileSmall) {
      font-size: 13px;
      right: 7%;
    }
  }
}

.email-confirm__error-container {
  display: flex;
  justify-content: center;
  padding-top: 45px;

  @media (max-width: $mobileSmall) {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    white-space: pre-line;
  }

  .email-confirm__error-text {
    width: 527px;
    height: 60px;
    color: #4d4d4d;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;

    @media (max-width: $tabletNew) {
      font-size: 22px;
    }

    @media (max-width: $mobileSmall) {
      font-size: 14px;
    }
  }
}