.payments-page {
  &__container {
    padding-top: 55px;
    padding-bottom: 55px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  // &__title {
  // }

  // &__inner {
  // }
}
//==========================================================================================================================================

// .payments {
// 	&__item {
// 	}

// 	&__link {
// 	}
// }

//========================= APPLY PROMOCODE ===================

.apply-promocode {
  &__container {
    height: 100vh;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 20px;
  }

  &__title {
    font-size: 40px;
    color: $colorBlueCrayola;

    @media (max-width: $tabletNew) {
      font-size: 32px;
    }

    @media (max-width: $mobileSmall) {
      font-size: 16px;
    }
  }

  &__selected-tariff-text {
    padding-top: 40px;
    font-size: 24px;
    color: #f094d7;

    @media (max-width: $tabletNew) {
      padding-top: 30px;
      font-size: 22px;
    }

    @media (max-width: $mobileSmall) {
      padding-top: 8px;
      font-size: 14px;
    }
  }

  &__month-and-price {
    font-size: 24px;
    color: #4d4d4d;
    font-weight: 300;
    padding-top: 18px;

    @media (max-width: $tabletNew) {
      font-size: 22px;
    }

    @media (max-width: $mobileSmall) {
      padding-top: 0px;
      font-size: 14px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form-group {
    display: flex;
    align-items: center;
    gap: 26px;
    padding-top: 30px;
    padding-right: 128px;

    @media (max-width: $mobileSmall) {
      padding-right: 72px;
      gap: 14px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    padding-top: 34px;

    @media (max-width: $mobileSmall) {
      padding-top: 20px;
      gap: 20px;
    }
  }

  &__submit-btn {
    width: 212px;
    height: 70px;
    font-size: 28px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: $tabletNew) {
      width: 175px;
      height: 54px;
      font-size: 24px;
      box-shadow: none;
    }

    @media (max-width: $tabletNew) {
      width: 115px;
      height: 33px;
      font-size: 16px;
      box-shadow: none;
    }
  }

  &__without-promo-btn {
    width: 271px;
    height: 38px;
    border-radius: 23px;
    border: 2px solid #f094d7;
    color: #f094d7;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 24px */
    text-decoration: underline;

    @media (max-width: $tabletNew) {
      width: 190px;
      height: 27px;
      font-size: 14px;
    }
  }

  &__input-form {
    width: 287px !important;
    height: 45px;
    text-align: center;
    font-weight: bold;
    border-radius: 14px;
    border: 1px solid #c0b2b2;
    background: #fff;

    @media (max-width: $mobileSmall) {
      width: 174px !important;
      height: 32px;
      font-size: 24px;
      box-shadow: none;
    }
  }
}

// =================== ERROR PROMOCODE ========================
.error-promocode {
  &__container {
    display: flex;
    justify-content: center;
    height: 100vh;
    padding-top: 30px;
    gap: 40px;

    @media (max-width: $tabletNew) {
      gap: 0px;
    }

    @media (max-width: $mobileSmall) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
    }
  }

  &__lama-ok-wrap {
    position: relative;
    height: 382px;
    width: 533px;
    //height: 426px;
    //width: 600px;

    @media (max-width: $tabletNew) {
      height: 343px;
      width: 490px;
    }

    @media (max-width: $mobileSmall) {
      height: 178px;
      width: 260px;
    }
  }

  &__lama-ok-background {
    width: 533px;

    @media (max-width: $tabletNew) {
      width: 478px;
    }

    @media (max-width: $mobileSmall) {
      width: 248px;
    }
  }

  &__lama-ok-lama-img {
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 266px;

    @media (max-width: $tabletNew) {
      width: 242px;
    }

    @media (max-width: $mobileSmall) {
      width: 124px;
    }
  }

  &__message-wrap {
    position: absolute;
    right: 2%;
    top: 28%;
    width: 129px;

    @media (max-width: $tabletNew) {
      width: 115px;
      right: 4%;
      top: 22%;
    }

    @media (max-width: $mobileSmall) {
      width: 62px;
      right: 5%;
      top: 22%;
    }
  }

  &__message-msg {
    position: absolute;
    right: 8%;
    top: 36%;
    white-space: pre-line;
    color: #4d4d4d;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media (max-width: $tabletNew) {
      font-size: 20px;
      top: 30%;
      right: 11%;
    }

    @media (max-width: $mobileSmall) {
      font-size: 13px;
      top: 29%;
      right: 11%;
    }
  }

  &__right-block-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 43px;
    width: 500px;

    @media (max-width: $tabletNew) {
      width: 400px;
    }

    @media (max-width: $mobileSmall) {
      gap: 35px;
    }
  }

  &__right-block-text-wrap {
    padding-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $tabletNew) {
      line-height: 100%;
    }

    @media (max-width: $mobileSmall) {
      padding-top: 0px;
      gap: 10px;
    }
  }

  &__right-block-header {
    font-size: 40px;
    color: #f094d7;

    @media (max-width: $tabletNew) {
      font-size: 32px;
      line-height: 100%;
    }

    @media (max-width: $mobileSmall) {
      font-size: 16px;
      gap: 25px;
      line-height: 100%;
    }
  }

  &__right-block-text {
    color: #4d4d4d;
    font-weight: 300;
    text-align: center;
    width: 350px;
    padding-top: 30px;

    @media (max-width: $tabletNew) {
      font-size: 22px;
      line-height: 100%;
    }

    @media (max-width: $mobileSmall) {
      width: 200px;
      padding-top: 10px;
      font-size: 14px;
      line-height: 100%;
    }
  }

  &__go-back-btn {
    width: 180px;
    height: 38px;
    border-radius: 23px;
    border: 2px solid #f094d7;
    color: #f094d7;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 24px */
    text-decoration-line: underline;

    @media (max-width: $mobileSmall) {
      width: 121px;
      height: 27px;
      font-size: 14px;
    }
  }
}
