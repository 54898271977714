//==========================LOGIN AND SIGNUP==========================================================================================================

.login-signup-page {
  &__cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 100px;
    gap: 10px;
    @media (min-width: $mobile) {
      padding-bottom: 150px;
      gap: 25px;
    }
  }

  &__img {
    width: 100%;
    max-width: 254px;
    @media (min-width: $mobile) {
      max-width: 512px;
    }
    @media (min-width: $pcSmall) {
      max-width: 553px;
    }
  }

  &__email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $mobile) {
      gap: 15px;
    }
  }

  &__social-container {
    padding-top: 52px;
    @media (max-width: $mobileSmall) {
      padding-top: 15px;
    }
  }

  &__social-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__socials {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 16px;
    @media (max-width: $mobileSmall) {
      padding-top: 5px;
    }
  }

  &__login-via-wrap {
    display: flex;
    gap: 18px;
    align-items: center;
    @media (max-width: $mobileSmall) {
      justify-content: center;
      gap: 16px;
    }
  }

  &__title {
    text-transform: uppercase;
    color: $colorBlueCrayola;
    padding-bottom: 40px;
    paddint-top: 24px;
    font-size: 40px;

    @media (max-width: $tabletNew) {
      font-size: 32px;
      padding-bottom: 32px;
    }

    @media (max-width: $mobileSmall) {
      font-size: 16px;
      padding-bottom: 20px;
      padding-right: 5px;
    }
  }

  &__active-link {
    color: #f094d7;
  }

  &__decoration-line {
    width: 328px;
    height: 1px;
    background: #a2daeb;

    @media (max-width: $mobileSmall) {
      width: 100px;
    }
  }

  &__enter-with {
    white-space: nowrap;

    @media (max-width: $mobileSmall) {
      font-size: 14px;
    }
  }

  .form-signup-email {
    justify-content: center;

    @media (max-width: $mobileSmall) {
      gap: 30px;
    }
  }
}

//=================================LOGIN=========================================================================================================

.login {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  svg {
    width: 35px;
    height: 35px;
  }
  &[title="Apple"] {
    svg {
      width: 50px;
      height: 50px;
    }
  }
  &__link {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    color: $colorBlueCrayola;
  }
}

// ==================== EMAIL ============================================================

.login-signup-form-email__cont {
  width: 600px;

  @media (max-width: $mobileSmall) {
    width: 300px !important;
    display: flex;
    justify-content: center;
  }

  .login-form-email {
    @media (max-width: $mobileSmall) {
      width: 300px;
      gap: 7px;
    }
  }

  .login-signup-form-body {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding-right: 80px;

    @media (max-width: $mobileSmall) {
      padding-right: 0px;
      gap: 15px;
    }
  }

  .footer-form {
    display: flex;
    justify-content: center;
    margin-left: 20px;
    gap: 67px;

    @media (max-width: $mobileSmall) {
      flex-direction: column;
      padding-right: 0px;
      padding-left: 58px;
      margin-left: 0px;
      gap: 2px;
    }
  }

  .form-group-ckeckbox {
    gap: 5px;
  }

  .form-group-ckeckbox .input-form {
    opacity: 0;
    position: absolute;
    width: 45px !important;
    margin-top: 15px;
  }

  .form-group-ckeckbox .input-form + .label-form {
    position: relative;
    padding-left: 23px;
    cursor: pointer;
    white-space: nowrap;

    @media (max-width: $mobileSmall) {
      padding-left: 15px;
    }
  }

  .form-group-ckeckbox .input-form + .label-form::before {
    content: "";
    position: absolute;
    left: 0;
    top: 55%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background-color: #fff;
    border: 2px solid #c0b2b2;

    @media (max-width: $mobileSmall) {
      width: 9px;
      height: 9px;
      border: 1px solid #c0b2b2;
    }
  }

  .form-group-ckeckbox .input-form:checked + .label-form::before {
    background-image: url("checkbox-checked.png");
    border: none;
    background-repeat: no-repeat;
    background-size: 17px;

    @media (max-width: $mobileSmall) {
      background-size: 9px;
    }
  }

  .forgot-password {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: $colorBlueCrayola;

    @media (max-width: $mobileSmall) {
      font-size: 14px;
      padding-left: 15px;
    }
  }

  .form-button-login-container {
    @media (max-width: $mobileSmall) {
      padding-top: 15px;
    }

    .form-button-login {
      width: 203px;
      height: 70px;

      @media (max-width: $mobileSmall) {
        width: 96px;
        height: 33px;
        display: flex;
        font-size: 16px;
      }
    }
  }
  .login-error-cont {
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.5;

    @media (max-width: $mobileSmall) {
      font-size: 16px;
    }
  }
}

// ==================== SIGNUP_EMAIL ============================================================

.signup-page__wrap {
  .signup__email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    @media (min-width: $mobile) {
      gap: 15px;
    }
  }

  .label-form-repeat-password {
    width: 70px;
  }

  .form-button-signup-container {
    @media (max-width: $mobileSmall) {
      padding-top: 0px;
    }

    .form-button-signup {
      width: 258px;
      height: 70px;

      @media (max-width: $mobileSmall) {
        width: 144px;
        height: 33px;
        display: flex;
        font-size: 16px;
      }
    }
  }
}
