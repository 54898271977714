.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 23px;
  gap: 18px;

  @media (min-width: $mobile) {
    gap: 30px;
    padding-top: 50px;
  }

  @media (min-width: $tablet) {
    padding-top: 90px;
  }

  .lessons & {
    padding-top: 17px;

    @media (min-width: $mobile) {
      padding-top: 0;
    }
  }

  &__subitem {
    .page-header__lessons & {
      &.active {
        a {
          color: $colorPinkAmaranth;
        }
      }
    }

    .page-header__profile & {
      &.active {
        a {
          text-shadow: 0 4px 4px rgba($color: $colorPurpleShadow, $alpha: 0.6);
        }
      }
    }
  }
}

//==========================================================================================================================================
