.about-landing {
  position: relative;
  background-color: $mainColor;
  svg {
    display: none;
    @media (min-width: $mobile) {
      display: block;
      width: 100%;
      position: absolute;
      top: -10%;
      z-index: 2;
      padding: 0 30px;
    }
    @media (min-width: $tablet) {
      top: -6%;
    }
    @media (min-width: $pcSmall) {
      top: -3%;
    }
  }
  &__container {
    position: relative;
    display: grid;
    justify-items: center;
    padding-bottom: 40px;
    @media (min-width: $mobile) {
      padding-bottom: 60px;
    }
    @media (min-width: $tablet) {
      padding-bottom: 105px;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 11px;
      @media (min-width: $mobile) {
        margin-bottom: 40px;
      }
    }
  }

  &__subtitle {
    color: $colorBlueCrayola;
    text-align: center;
    @media (max-width: $mobile) {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  &__list {
    width: 100%;
    display: grid;
    @media (max-width: $mobile) {
      gap: 21px;
    }
    @media (min-width: $mobile) {
      max-width: 850px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $pcSmall) {
      max-width: 1045px;
    }
  }

  &__item {
    position: relative;
    display: grid;
    gap: 7px;
    justify-self: flex-start;
    width: 100%;
    @media (min-width: $mobile) {
      gap: 16px;
    }
    @media (min-width: $pcSmall) {
      gap: 32px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      @media (max-width: $mobile) {
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 129px;
          height: 129px;
          top: 50%;
          transform: translateY(-50%);
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &:nth-child(1) {
      @media (max-width: $mobile) {
        &::after {
          content: "";
          right: 0;
          background-image: url("../images/background/about_cloud.png");
          background-size: 110%;
        }
      }
    }
    &:nth-child(2) {
      color: $colorBlueCrayola;
      justify-content: end;
      & .text {
        text-align: right;
      }
      @media (max-width: $mobile) {
        &::after {
          content: "";
          left: 0;
          background-image: url("../images/background/about_cloud_left.png");
          background-size: 110%;
        }
      }
    }
  }
  &__inner {
    position: relative;
    width: 100%;
    height: 500px;
    max-height: 255px;
    background-image: url("../images/background/about_home.png");
    background-position: 105% center;
    background-repeat: no-repeat;
    background-size: 28%;
    @media (max-width: $mobile) {
      padding-top: 15px;
      background-position: 105% 113%;
    }
    @media (min-width: $mobile) {
      max-height: 440px;
    }
    @media (min-width: $pcSmall) {
      max-height: none;
    }
  }
  &__img {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 310px;

    @media (min-width: $mobile) {
      max-width: 560px;
      top: -35px;
    }
    @media (min-width: $tablet) {
      max-width: 617px;
      top: -100px;
    }
    @media (min-width: $pcSmall) {
      max-width: 790px;
      top: -137px;
    }
  }
  &__text {
    max-width: 178px;
    @media (min-width: $mobile) {
      max-width: 340px;
    }
    &.subtitle {
      text-align: center;
    }
  }

  &__description {
    max-width: 600px;
    text-align: center;
    color: $colorBlueCrayola;
    padding-top: 40px;
    @media (max-width: $mobile) {
      max-width: 330px;
    }
    @media (min-width: $tablet) {
      max-width: 860px;
    }
  }
}
