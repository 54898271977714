@use "sass:math";

//wagtail
wagtail-userbar {
  all: initial;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 20px;
}

//==========================================================================================================================================
body {
  color: $colorPinkAmaranth;
  line-height: 130%;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: 800;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//==========================================================================================================================================

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @supports (overflow: clip) {
    overflow: clip;
  }
  > main {
    flex: 1 1 auto;
  }

  > * {
    min-width: 0;
  }
}

//==========================================================================================================================================

[class*="__container"] {
  @if ($maxWidthContainer>0) {
    max-width: $maxWidthContainer + px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1920px) {
      max-width: $maxWidthBigContainer + px;
    }

    .landing & {
      @media (min-width: 1920px) {
        max-width: $maxWidthlandingContainer + px;
      }
    }
    .plans & {
      max-width: $maxWidthContainer + px;
    }
    .expectation-landing & {
      @media (min-width: 1441px) {
        max-width: $maxWidthlandingContainer + px;
      }
    }
  }

  @if ($containerPadding>0) {
    padding: 0 calc($containerPadding / 2) + px;
  }
}

//==========================================================================================================================================
.main {
  .landing &,
  .plans &,
  .profile-page & {
    background-image: url("../images/background/all_bg.png");
    background-position: center;
    background-repeat: repeat;

    @media (max-width: $mobile) {
      background-size: contain;
    }
  }
  &-lessons {
    ~ .footer {
      background-color: $colorCandyPink;
    }
  }
}

//==========================================================================================================================================

.page-not {
  &__container {
    padding-top: 23px;
    padding-bottom: 150px;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    @media (max-width: $mobile) {
      flex-direction: column;
    }
    @media (min-width: $mobile) {
      padding-top: 50px;
      padding-bottom: 200px;
      gap: 20px;
    }

    @media (min-width: $pcSmall) {
      padding-top: 70px;
      padding-bottom: 250px;
      gap: 74px;
    }
  }

  &__img {
    max-width: 247px;
    @media (min-width: $mobile) {
      max-width: 370px;
    }
    @media (min-width: $tablet) {
      max-width: 479px;
    }
    @media (min-width: $pcSmall) {
      max-width: 594px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    @media (max-width: $mobile) {
      margin-top: 12px;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    @media (min-width: $mobile) {
      &:not(:last-child) {
        margin-bottom: 26px;
      }
    }
  }

  &__subtitle {
    color: $colorBlueCrayola;
    max-width: 185px;
    &:not(:last-child) {
      margin-bottom: 23px;
    }
    @media (min-width: $mobile) {
      max-width: 365px;
      &:not(:last-child) {
        margin-bottom: 44px;
      }
    }
    @media (min-width: $pcSmall) {
      max-width: 456px;
    }
  }
  & .button {
    &:not(:last-child) {
      margin-bottom: 17px;
    }
    @media (min-width: $mobile) {
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }
    @media (min-width: $pcSmall) {
      &:not(:last-child) {
        margin-bottom: 44px;
      }
    }
  }
}

//==========================================================================================================================================
.list {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

//==========================================================================================================================================
[class*="__bg-pattern"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

//==========================================================================================================================================
[class*="__background"] {
  position: absolute;
  z-index: -1;
}

//==========================================================================================================================================
.close {
  & .header__body {
    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.open {
  & .header__body {
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      z-index: 10;
      top: 57px;
      right: 10%;
      width: 100%;
      max-width: 200px;
      padding: 25px;
      background-color: $mainColor;
      border-radius: 17px;
      border: 0.3px solid $colorPinkAmaranth;
      box-shadow: 3px 4px 4px 0 $colorPinkAmaranth;
    }

    & .menu.header__menu {
      @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        &::after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 0.5px solid $colorBlueCrayola;
          margin-bottom: 12px;
        }
      }
    }
  }

  & .profile__list {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    right: 10px;
  }
}

//==========================================================================================================================================
.title-color,
.text-color {
  color: $darkColor;
}

//==========================================================================================================================================

.menu {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (min-width: $mobile) {
    justify-content: center;
  }
  &.header__menu {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  &.footer__menu {
    display: none;
  }
  .landing & {
    &.footer__menu {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: $tablet) and (min-width: $mobile) {
        flex-direction: row;
        align-items: center;
      }
      @media (max-width: $mobile) {
        align-items: center;
      }
    }
  }
  .nav-profile & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 26px;

    &__item {
      &::after {
        content: none;
      }
    }

    @media (min-width: $mobile) {
      display: none;
      visibility: hidden;
      height: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: all 0.6s ease 0s;

    &::after {
      transition: color 0.6s ease 0s;
      content: "|";
      font-size: inherit;

      @media (max-width: $mobile) {
        content: none;
      }
    }
    .footer__menu & {
      &:last-child::after {
        content: "|";
        @media (max-width: $tablet) {
          content: none;
        }
      }
    }
    &:last-child {
      &::after {
        content: none;
      }
    }

    &:hover {
      color: $colorPinkAmaranth;

      &::after {
        color: $colorPink;
      }
    }

    &.active a {
      color: $colorPinkAmaranth;
    }
  }
}

//==========================================================================================================================================

.nav-profile {
  width: 100%;

  @media (max-width: $mobile) {
    &::after {
      content: "";
      padding-top: 7px;
      display: block;
      width: 100%;
      border-bottom: 0.5px solid $colorBlueCrayola;
    }
  }

  @media (min-width: $mobile) {
    &.profile__item {
      padding: 0;
    }
  }
}

//==========================================================================================================================================
.sub-list {
  display: flex;
  gap: 10px;
  padding-top: 10px;
  @media (min-width: $mobile) {
    padding-top: 14px;
    gap: 26px;
    justify-self: flex-start;
  }
  @media (min-width: $pcSmall) {
    padding-top: 21px;
  }

  &__subitem {
    color: $colorBlueLink;
    transition: all 0.6s ease 0s;

    &:hover {
      color: $colorPinkAmaranth;
    }

    &.active {
      color: $colorPinkAmaranth;
    }
  }
}

//==========================================================================================================================================
.static-page {
  &__container {
    padding-top: 50px;
    padding-bottom: 150px;
    color: $darkColor;
  }
  &__description {
    display: grid;
    justify-items: start;
    gap: 15px;
    color: $darkColor;
    text-align: left;
    &:not(:empty) {
      padding-top: 25px;
    }
    li {
      margin-bottom: 15px;
    }
  }
}
