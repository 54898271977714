// Color
$mainColor: #ffffff;
$colorBlueCrayola: #81d5ee;
$colorPink: #ed8fca;
$colorPinkAmaranth: #f094d7;
$backgPink: #f8cae7;
$colorCandyPink: #ffc1ee;
$colorBlueShadow: #51b8d7;
$darkColor: #4d4d4d;
$colorBlue: #a2daeb;
$colorBlueLink: #4cd0f9;
$colorPurple: #d832ef;
$colorChoice: #ba31c6;
$colorMotiv: #a05aa6;
$colorNumber: #8d5292;
$colorDarkPurple: #651f52;
$colorPurpleShadow: #ab4f92;
$colorButtonShadow: #c65ea9;
$colorLogo: #ffa0d9;
$colorYellow: #ead050;

//==========================================================================================================================================

$ruby: #ff6854;
$amber: #ffa755;
$tigersEye: #ffe455;
$emerald: #daff71;
$aquamarine: #80f7ff;
$sapphire: #3a95e9;
$purple: #a365f2;
