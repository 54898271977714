.children {
  color: $mainColor;
  position: relative;
  z-index: 1;
  &__container {
    padding-bottom: 150px;
    display: grid;
    justify-items: center;
    justify-content: center;
    @media (min-width: $mobile) {
      padding-bottom: 200px;
    }
  }

  &__list {
    width: 100%;
    display: grid;
    justify-items: center;
    &-column {
      @media (min-width: 1240px) {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  &__item {
    @media (min-width: $tablet) {
      &:nth-child(1) {
        margin-top: 55px;
        justify-self: flex-start;
      }
      &:nth-child(2) {
        margin-top: -55px;
        justify-self: flex-end;
      }
      &:nth-child(3) {
        @media (min-width: 1350px) {
          grid-column: 1/3;
          justify-self: center;
          margin-top: -180px;
        }
      }
    }
  }

  &__text {
    padding-top: 25px;
    color: $colorPinkAmaranth;
    text-decoration: underline;
    justify-self: center;
  }
}
