// fonts
@font-face {
  font-family: "Neucha";
  font-display: swap;
  src: url("../fonts/Neucha.woff2") format("woff2"),
    url("../fonts/Neucha.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  font-display: swap;
  src: url("../fonts/NotoSans-Regular.woff2") format("woff2"),
    url("../fonts/NotoSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Kardina";
  font-display: swap;
  src: url("../fonts/KardinalPro-Light.woff2") format("woff2"),
    url("../fonts/KardinalPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Kardina";
  font-display: swap;
  src: url("../fonts/KardinalPro-ExtraBold.woff2") format("woff2"),
    url("../fonts/KardinalPro-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

//==========================================================================================================================================
.logo__text {
  font-family: "Neucha", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 109%;

  @media (max-width: $mobile) {
    font-size: 24px;
    line-height: 107%;
  }
}
.social-landing__text {
  font-family: "Neucha", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  @media (min-width: $mobile) {
    font-size: 18px;
  }
  @media (min-width: $pcSmall) {
    font-size: 28px;
  }
}
.footer__text {
  font-family: "Neucha", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
}
.promo-landing__title {
  font-size: 20px;
  font-weight: 800;
  line-height: 95%;

  @media (min-width: $mobile) {
    font-size: 47px;
  }

  @media (min-width: $pcSmall) {
    font-size: 73.2px;
  }
}

.plans__numder {
  font-family: "Neucha", sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 65%;

  @media (min-width: $mobile) {
    font-size: 100px;
  }

  @media (min-width: $pcSmall) {
    font-size: 170px;
  }
}

.child__score,
.text-weight {
  font-family: "NotoSans", sans-serif;
  font-weight: 400;
  line-height: 120%;
  font-size: 16px;

  @media (max-width: $mobile) {
    font-size: 8px;

    &:not(.text-weight) {
      font-size: 12px;
    }
  }
}

.card__title {
  font-size: 12px;
  line-height: 120%;

  @media (min-width: $mobile) {
    font-size: 16px;
  }

  @media (min-width: $pcSmall) {
    font-size: 20px;
  }
}

.text-thin {
  font-weight: 300;
  line-height: normal;

  @media (max-width: $mobile) {
    font-size: 12.6px;
  }
  &.promo-landing__subtitle {
    @media (min-width: $mobile) {
      font-size: 25px;
    }
    @media (min-width: $pcSmall) {
      // font-size: 33.5px;
      font-size: 46.2px;
    }
  }
}

.choise__title {
  font-size: 28px;
  line-height: normal;

  @media (max-width: $mobile) {
    font-size: 20px;
  }
}
.title-access {
  font-size: 20px;
  line-height: normal;
  @media (min-width: $mobile) {
    font-size: 35px;
  }
  @media (min-width: 1100px) {
    font-size: 36px;
    &-big {
      font-size: 48px;
    }
  }
}
.title {
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  &-little {
    font-size: 14px;
  }
  @media (min-width: $mobile) {
    font-size: 32px;
  }

  @media (min-width: $pcSmall) {
    font-size: 40px;
  }
}
.promo-landing__text {
  font-size: 11.5px;
  line-height: normal;
  @media (min-width: $mobile) {
    font-size: 31px;
  }
  @media (min-width: $pcSmall) {
    font-size: 42px;
  }
}
.title-small {
  font-size: 16px;
  line-height: normal;
  @media (min-width: $mobile) {
    font-size: 28px;
  }
  @media (min-width: $pcSmall) {
    font-size: 36px;
  }
}
.page-header__text {
  font-size: 24px;
  line-height: 120%;

  @media (min-width: $mobile) {
    font-size: 32px;
  }

  @media (min-width: $tablet) {
    font-size: 42px;
  }
}

.title-nav {
  font-size: 32px;
  line-height: normal;
  text-transform: uppercase;

  @media (min-width: $mobile) {
    font-size: 67px;
  }

  @media (min-width: $tablet) {
    font-size: 90px;
  }
}

.subtitle {
  font-size: 32px;
  font-weight: 800;
  line-height: normal;
  @media (max-width: $pcSmall) {
    &.lesson-landing__subtitle {
      font-size: 24px;
    }
    .bubble & {
      font-size: 24px;
    }
  }
  @media (max-width: $tablet) {
    font-size: 24px;
  }
  @media (max-width: $mobile) {
    font-size: 16px;
  }

  :not(.course__sublist) & {
    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }
}
.subtitle-toy {
  font-size: 15px;
  line-height: normal;
  font-weight: 800;
  @media (min-width: $mobile) {
    font-size: 32px;
  }
  @media (min-width: $pcSmall) {
    font-size: 42px;
  }
}
.button {
  font-size: 14px;
  line-height: 100%;
  font-weight: 800;
  @media (min-width: $mobile) {
    font-size: 22px;
  }

  @media (min-width: $tablet) {
    font-size: 32px;
  }
  .landing & {
    @media (max-width: $tablet) {
      font-size: 24px;
    }
    @media (max-width: $mobile) {
      font-size: 16px;
    }
  }
  &-outline,
  &-user {
    @media (min-width: $tablet) {
      font-size: 28px;
    }
  }
}

.landing_text_in_eggs {
  font-size: 19px;
  //color: #81d5ee;
  color: $colorBlueCrayola;
  line-height: 130%;
  max-width: 432px;

  &:not(:last-child) {
    padding-bottom: 4px;
  }

  @media (max-width: $pcSmall) {
    max-width: 324px;
    font-size: 16px;
  }

  //@media (max-width: $tablet) {
  //  max-width: 324px;
  //  font-size: 18px;
  //}

  @media (max-width: $mobile) {
    max-width: 240px;
    font-size: 10px;
  }
}

.text,
.errorlist {
  font-size: 24px;
  line-height: normal;
  @media (max-width: $pcSmall) {
    &.lesson-landing__text {
      font-size: 18px;
    }
    &.choise__text {
      font-size: 22px;
    }
  }
  @media (max-width: $tablet) {
    font-size: 18px;
    &.motivation-landing__text {
      font-size: 22px;
    }
  }

  @media (max-width: $mobile) {
    font-size: 12px;
    &.lesson-landing__text {
      font-size: 12px;
    }
    &.user__text,
    &.about-landing__text {
      font-size: 14px;
    }
    &.choise__text {
      font-size: 16px;
    }
  }
}

.button-border {
  font-size: 16px;
  line-height: 107%;

  @media (min-width: $mobile) {
    font-size: 20px;
  }

  @media (min-width: $tablet) {
    font-size: 24px;
  }
}

.header .button,
.text-menu {
  font-size: 14px;
  line-height: 130%;

  @media (min-width: $mobile) {
    font-size: 18px;
  }

  @media (min-width: $tablet) {
    font-size: 24px;
  }
}

.footer {
  & .feedback {
    & .button {
      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }
  }
  & .button {
    font-size: 16px;
    line-height: normal;

    @media (min-width: $mobile) {
      font-size: 18px;
    }

    @media (min-width: $pcSmall) {
      font-size: 24px;
    }
  }
  & .text {
    @media (max-width: $mobile) {
      font-size: 16px;
    }
    @media (max-width: $pcSmall) {
      font-size: 18px;
    }
  }
}
.text-small {
  font-size: 10px;
  line-height: 118%;

  @media (min-width: $mobile) {
    font-size: 13px;
  }

  @media (min-width: $tablet) {
    font-size: 14px;
  }
}

.text-middle {
  font-size: 8px;
  line-height: 130%;

  @media (min-width: $tablet) {
    font-size: 16px;
  }
}

.text-big {
  font-size: 14px;
  line-height: normal;

  @media (min-width: $mobile) {
    font-size: 34px;
  }

  @media (min-width: $pcSmall) {
    font-size: 44px;
  }
}
.text-mid {
  font-size: 14px;
  line-height: normal;

  @media (min-width: $mobile) {
    font-size: 27px;
  }

  @media (min-width: $pcSmall) {
    font-size: 36px;
  }
}

.finish-text {
  font-size: 16px;
  line-height: 100%;

  @media (min-width: $mobile) {
    font-size: 32px;
  }
  @media (min-width: $pcSmall) {
    font-size: 50px;
    .login-page & {
      font-size: 40px;
    }
  }
}

//==========================================================================================================================================
