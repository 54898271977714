.achievements {
  &__container {
    padding-top: 20px;
    padding-bottom: 150px;

    @media (min-width: $mobile) {
      padding-bottom: 200px;
      padding-top: 60px;
    }
    @media (min-width: $pcSmall) {
      padding-top: 68px;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__images {
    max-width: 300px;
    @media (min-width: $mobile) {
      max-width: 465px;
    }
    @media (min-width: $tablet) {
      max-width: 654px;
    }
  }

  //   &__title {
  //   }
}

//==========================================================================================================================================

.range {
  padding-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 50px;

  @media (min-width: $tablet) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  &__subtitle {
    text-align: center;
    color: $colorBlueCrayola;
    padding-bottom: 6px;
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__img {
    max-width: 31px;
    @media (min-width: $mobile) {
      max-width: 74px;
    }
    @media (min-width: $tablet) {
      max-width: 85px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__text {
    text-transform: lowercase;
  }
}

//==========================================================================================================================================

// .price {
//   &__text {
//   }
// }

//==========================================================================================================================================

// .level {
// }

//==========================================================================================================================================
.desire {
  @media (max-width: $tablet) {
    grid-column: 1/3;
  }
}

//==========================================================================================================================================

.my-toy {
  padding-top: 60px;

  @media (min-width: $mobile) {
    padding-top: 100px;
  }

  @media (min-width: $tablet) {
    padding-top: 120px;
  }

  &__title {
    color: $colorBlueCrayola;
  }

  &__wrap {
    padding-top: 60px;

    @media (min-width: $mobile) {
      padding-top: 90px;
    }
  }

  &__inner {
    padding-top: 25px;
    display: grid;
    gap: 10px;
    justify-items: center;
  }

  // &__text {
  // }
}
