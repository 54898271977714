.promo-landing {
  &__container {
    position: relative;
    display: flex;
    padding-top: 130px;
    max-width: 1345px;
    @media (max-width: $tablet) {
      padding-top: 100px;
    }
    @media (max-width: $mobile) {
      padding-top: 0;
      padding-right: 0;
      justify-content: center;
      padding-bottom: 60px;
    }
  }

  &__info {
    max-width: 642px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: $pcSmall) {
      max-width: 495px;
    }
    @media (max-width: $mobile) {
      min-width: 180px;
      max-width: 495px;
      padding-top: 50px;
      justify-content: center;
    }
  }

  &__title {
    @media (max-width: $mobile) {
      max-width: 180px;
    }
  }

  &__subtitle {
    color: $darkColor;
    white-space: nowrap;
  }
  &__box {
    margin-top: 50px;
    margin-bottom: 40px;
    @media (max-width: $mobile) {
      margin-bottom: 15px;
      margin-top: 21px;
    }
  }
  &__text {
    color: $colorBlue;
    text-transform: none;
    text-align: left;
    &:nth-child(1) {
      white-space: nowrap;
    }
    &-color {
      color: $colorPinkAmaranth;
    }
    @media (max-width: $mobile) {
      max-width: 177px;
    }
  }

  &__img {
    width: 100%;
    max-width: 60px;
    margin-left: 23px;
    @media (min-width: $mobile) {
      max-width: 175px;
    }

    @media (min-width: $tablet) {
      max-width: 180px;
      margin-left: 80px;
    }
    @media (min-width: $pcSmall) {
      max-width: 221px;
      margin-left: 120px;
    }
  }

  &__images {
    &-big {
      width: 100%;
      max-width: 740px;
      position: absolute;
      top: 32px;
      right: -5%;
      @media (max-width: 1919.88px) {
        max-width: 650px;
      }
      @media (max-width: 1354px) {
        max-width: 550px;
        right: 0;
      }
      @media (max-width: 1230px) {
        max-width: 490px;
        right: 5%;
      }
      @media (max-width: 1074px) {
        right: 0;
      }
      @media (max-width: $tablet) {
        right: -5%;
      }
      @media (max-width: 890px) {
        max-width: 360px;
      }
      @media (max-width: $mobile) {
        display: none;
      }
    }

    &-small {
      width: 112%;
      position: relative;
      @media (min-width: $mobileSmall) {
        width: 100%;
      }
      @media (min-width: $mobile) {
        display: none;
      }
    }
  }
  & .button {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
  }
}
