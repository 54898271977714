// variables
$fontFamily: "Kardina", sans-serif;
$fontSize: 24px;

// Size
$minWidth: 360;
$maxWidth: 1920;
$maxWidthContainer: 1282;
$maxWidthlandingContainer: 1440;
$maxWidthBigContainer: 1550;

$containerPadding: 60;
$containerWidth: $maxWidthContainer + $containerPadding;

// Break point
$pc: 1919.98px;
$pcSmall: 1239.98px;
$tablet: 991.98px; //em(991.98)
$mobile: 767.98px; //em(767.98)
$mobileSmall: 479.98px; //em(479.98)
$tabletNew: 1024px;