.continue {
  &__container {
    min-height: 50vw;
    padding-top: 55px;
    padding-bottom: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/login/loading.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    .title {
      color: $colorBlueCrayola;
      font-weight: 800;
    }
  }
}
//==========================================================================================================================================
