// ==================== PASSWORD-RESET ============================================================

.password-reset__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 5px;

  @media (max-width: $tabletNew) {
    gap: 34px;
  }

  @media (max-width: $mobileSmall) {
    gap: 22px;
  }

  .password-reset__header {
    padding-top: 22px;
    color: #81d5ee;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;

    @media (max-width: $tabletNew) {
      font-size: 32px;
    }

    @media (max-width: $mobileSmall) {
      font-size: 16px;
    }
  }

  .form-reset-password {
    display: flex;
    gap: 44px;
    justify-content: center;
    padding-right: 70px;

    @media (max-width: $mobileSmall) {
      padding-right: 0px;
      gap: 36px;
    }
  }

  .form-group-reset-password {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;

    @media (max-width: $mobileSmall) {
      padding-right: 50px;
      gap: 14px;
    }
  }

  .form-reset-password-button__wrap-one {
    display: flex;
    justify-content: flex-end;
  }

  .form-reset-password-button__wrap-two {
    display: flex;
    justify-content: center;
    width: 419px;
  }

  .form-reset-password-button__btn {
    width: 234px;
    height: 44px;
    border-radius: 23px;
    border: 2px solid #f094d7;
    color: #f094d7;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 24px */
    text-decoration-line: underline;

    @media (max-width: $tabletNew) {
      width: 180px;
      height: 38px;
      font-size: 20px;
    }

    @media (max-width: $mobileSmall) {
      width: 122px;
      height: 26px;
      font-size: 14px;
    }
  }
}

// ==================== PASSWORD-RESET-DONE ============================================================

.password-reset-done__header {
  color: #81d5ee;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 32px */
}

.password-reset-done__text-container {
  flex-direction: column;
  gap: 24px;

  @media (max-width: $mobileSmall) {
    width: 291px;
    gap: 14px;
  }

  .password-reset-done__text {
    font-size: 24px;

    @media (max-width: $tabletNew) {
      font-size: 22px;
    }

    @media (max-width: $mobileSmall) {
      font-size: 14px;
    }
  }
}

// ==================== PASSWORD-RESET-FROM-KEY ============================================================

.password-reset-from-key-page {
  display: flex;
  justify-content: center;

  .password-reset-from-key__container {
    display: flex;
    justify-content: center;
    padding-right: 100px;
    padding-top: 5px;

    @media (max-width: $mobileSmall) {
      padding: 0px;
    }
  }

  .password-reset-from-key__success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    gap: 54px;

    @media (max-width: $mobileSmall) {
      gap: 22px;
    }

    .password-reset-from-key__header-container {
      padding-top: 22px;
      padding-left: 120px;
      color: #81d5ee;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */

      @media (max-width: $mobileSmall) {
        padding-top: 0px;
        padding-left: 0px;
      }

      .password-reset-from-key__header {
        @media (max-width: $tabletNew) {
          font-size: 32px;
        }

        @media (max-width: $mobileSmall) {
          font-size: 16px;
        }
      }
    }

    .form-password-reset-from-key {
      width: 600px;

      @media (max-width: $mobileSmall) {
        width: 300px;
      }

      input#id_password1,
      input#id_password2 {
        width: 419px !important;
        height: 45px;
        text-align: center;
        font-weight: bold;

        border-radius: 14px;
        border: 1px solid #c0b2b2;
        background: #fff;

        &::placeholder {
          color: transparent;
        }

        @media (max-width: $mobileSmall) {
          width: 202px !important;
          font-size: 14px;
          height: 32px;
        }
      }

      .form-button-password-reset-from-key-container {
        padding-left: 132px;
        padding-top: 25px;

        @media (max-width: $mobileSmall) {
          padding-left: 0px;
          padding-top: 0px;
        }

        .form-button-password-reset-from-key {
          width: 223px;
          height: 70px;
          color: #fff;
          font-size: 28px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;

          @media (max-width: $mobileSmall) {
            width: 125px;
            height: 33px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .password-reset-from-key-fail__cont {
    display: flex;
    height: 479px;
    align-items: center;

    @media (max-width: $mobileSmall) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  .password-reset-from-key__message-wrap {
    position: absolute;
    right: 5%;
    top: 25%;
    width: 129px;

    @media (max-width: $tabletNew) {
      width: 115px;
      right: 10%;
    }

    @media (max-width: $mobileSmall) {
      width: 62px;
    }
  }

  .password-reset-from-key__message-msg {
    position: absolute;
    right: 11%;
    top: 32%;
    white-space: pre-line;
    color: #4d4d4d;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media (max-width: $tabletNew) {
      font-size: 20px;
      top: 33%;
      right: 17%;
    }

    @media (max-width: $mobileSmall) {
      font-size: 13px;
      top: 32%;
      right: 16%;
    }
  }

  .password-reset-from-key__right-block-container {
    display: flex;
    flex-direction: column;
    gap: 43px;
    display: flex;

    @media (max-width: $mobileSmall) {
      gap: 25px;
    }

    .password-reset-from-key__right-block__text-wrap {
      padding-top: 0px;

      @media (max-width: $mobileSmall) {
        gap: 10px;
      }
    }

    .password-reset-from-key__right-block-header {
      font-size: 40px;
      color: #f094d7;

      @media (max-width: $tabletNew) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (max-width: $mobileSmall) {
        font-size: 16px;
        gap: 25px;
        line-height: 100%;
      }
    }

    .password-reset-from-key__right-block-text {
      white-space: pre-line;
      color: #81d5ee;
      @media (max-width: $mobileSmall) {
        font-size: 16px;
        line-height: 100%;
      }
    }

    .form-password-reset-from-key-button__container {
      display: flex;
      justify-content: center;

      .form-password-reset-from-key-button__btn {
        width: 180px;
        height: 38px;
        border-radius: 23px;
        border: 2px solid #f094d7;
        color: #f094d7;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 24px */
        text-decoration-line: underline;

        @media (max-width: $mobileSmall) {
          display: none;
        }
      }

      .form-password-reset-from-key-button__btn-mobile {
        display: none;

        @media (max-width: $mobileSmall) {
          display: inline-block;
          width: 110px;
          height: 27px;
          flex-shrink: 0;
          border-radius: 23px;
          border: 2px solid #f094d7;
          color: #f094d7;
          font-size: 14px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%; /* 16.8px */
          text-decoration-line: underline;
        }
      }
    }
  }
}

.label-form[for="password1"],
.label-form[for="password2"] {
  width: 100px;
}

// ==================== PASSWORD-RESET-FROM-KEY-DONE ===========================================================

.password-reset-from-key-done__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 400px;
  text-align: center;

  @media (max-width: $tabletNew) {
    width: 350px;
  }

  @media (max-width: $mobileSmall) {
    width: 200px;
    gap: 16px;
  }
}