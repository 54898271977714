.expectation-landing {
  position: relative;
  background-image: url("../images/background/expectation_bg.svg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: $mobileSmall) {
    background-image: url("../images/background/expectation_bg_small.svg");
    background-position: center -20px;
  }
  &__container {
    padding-top: 175px;
    padding-bottom: 365px;

    @media (min-width: 2115px) {
      padding-top: 230px;
    }

    @media (max-width: $pcSmall) {
      padding-top: 145px;
    }

    @media (max-width: $tablet) {
      padding-top: 240px;
    }

    @media (max-width: $mobile) {
      padding-bottom: 165px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 140px;
    }
    @media (max-width: $mobileSmall) {
      padding-bottom: 85px;
      padding-top: 60px;
    }
  }

  &__title {
    color: $colorBlueCrayola;
    &:not(:last-child) {
      padding-bottom: 265px;

      @media (max-width: $tablet) {
        padding-bottom: 320px;
      }

      @media (max-width: $mobile) {
        padding-bottom: 150px;
      }
      @media (max-width: $mobileSmall) {
        padding-bottom: 100px;
      }
    }
  }
}

//==========================================================================================================================================

.lesson-landing {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    gap: 40px;

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    @media (min-width: $tablet) {
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 140px;
      }

      &.system {
        flex-direction: row-reverse;
      }
    }

    @media (min-width: $tablet) {
      gap: 7vw;
    }
  }

  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 198px;

    @media (min-width: $mobile) {
      max-width: 339px;
    }

    @media (min-width: $pcSmall) {
      max-width: 471px;
    }

    svg {
      position: absolute;
      width: 100%;
    }

    img {
      max-width: 198px;

      @media (min-width: $mobile) {
        max-width: 339px;
      }

      @media (min-width: $pcSmall) {
        max-width: 471px;
      }
    }
  }

  &__body {
    position: relative;

    svg {
      position: absolute;
      width: 100%;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  &__subtitle {
    margin-left: 96px;

    .metod__body & {
      margin-left: 76px;
    }

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    .meditation & {
      margin-left: 19px;
      max-width: 370px;
      text-align: center;

      @media (max-width: $pcSmall) {
        max-width: 350px;
      }

      @media (max-width: $mobile) {
        max-width: 200px;
        margin-left: 10px;
      }
    }

    @media (max-width: $pcSmall) {
      margin-left: 55px;
    }

    @media (max-width: $mobile) {
      margin-left: 74px;

      .system & {
        margin-left: 76px;
      }

      &:not(:last-child) {
        padding-bottom: 9px;
      }
    }
  }

  &__text {
    color: $colorBlueCrayola;
    max-width: 432px;

    &:not(:last-child) {
      padding-bottom: 10px;
    }

    @media (max-width: $pcSmall) {
      max-width: 324px;
    }

    @media (max-width: $mobile) {
      max-width: 240px;
    }
  }
}

//==========================================================================================================================================
.metod__body,
.system__body,
.meditation__body {
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: $mobile) {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -11%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
    }
  }
}

.metod__img,
.system__img,
.meditation__img {
  svg {
    width: 106%;
  }

  @media (min-width: $tablet) {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -10%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
    }
  }
}

.metod {
  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -12%;
    left: 0;

    @media (max-width: $tablet) {
      top: -22%;

      path {
        stroke-width: 11px;
      }
    }

    @media (max-width: $mobile) {
      top: -23%;
    }
  }

  @media (min-width: $pcSmall) {
    padding-left: 64px;
  }

  &__img::after {
    left: -10%;
    max-width: 180px;
    max-height: 180px;
    background-image: url("../images/expectation/sun.png");
  }

  &__body {
    svg {
      width: 106%;

      @media (max-width: $mobile) {
        width: 96%;
        left: 14px;
      }
    }

    &::after {
      right: 10%;
      max-width: 150px;
      max-height: 158px;
      background-image: url("../images/expectation/pulse.png");

      @media (max-width: $pcSmall) {
        top: -15%;
      }

      @media (min-width: $pcSmall) {
        right: 18%;
      }
    }

    .lesson-landing__inner {
      padding: 44px 107px 83px 84px;

      @media (max-width: $pcSmall) {
        padding: 66px 90px 56px 77px;
      }

      @media (max-width: $mobile) {
        padding: 45px 56px 22px 65px;
      }
    }
  }
}

.system {
  @media (min-width: $pcSmall) {
    padding-right: 64px;
  }

  @media (max-width: $mobile) {
    gap: 60px;

    &:not(:last-child) {
      margin-bottom: 55px;
    }
  }

  &__img::after {
    right: -15%;
    max-width: 197px;
    max-height: 197px;
    background-image: url("../images/expectation/cloud.png");
  }

  &__body {
    &::after {
      left: -10%;
      max-width: 150px;
      max-height: 158px;
      background-image: url("../images/expectation/system_pulse.png");

      @media (min-width: $pcSmall) {
        left: -8%;
        top: 5%;
      }
    }

    .lesson-landing__inner {
      padding: 52px 30px 100px 128px;

      @media (max-width: $pcSmall) {
        padding: 48px 45px 80px 125px;
      }

      @media (max-width: $mobile) {
        padding: 0 30px 17px 55px;
      }
    }
  }
}

.meditation {
  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -35%;
    left: 0;

    @media (max-width: $tablet) {
      bottom: -38%;
    }

    @media (max-width: $mobile) {
      bottom: -20%;
    }
  }

  @media (min-width: $pcSmall) {
    padding-left: 64px;
  }

  @media (min-width: 1570px) {
    > svg {
      bottom: -32%;
    }
  }

  &__img {
    img {
      max-width: 217px;

      @media (min-width: $mobile) {
        max-width: 339px;
      }

      @media (min-width: $pcSmall) {
        max-width: 490px;
      }
    }

    svg {
      @media (min-width: $mobile) {
        width: 97%;
      }

      @media (min-width: $pcSmall) {
        width: 100%;
      }
    }

    ::after {
      left: -25%;
      max-width: 250px;
      max-height: 250px;
      background-image: url("../images/expectation/meditation-cloud.png");
    }
  }

  &__body {
    &::after {
      right: -8%;
      max-width: 150px;
      max-height: 158px;
      background-image: url("../images/expectation/meditation-pulse.png");

      @media (min-width: $pcSmall) {
        top: 5%;
      }
    }

    .lesson-landing__inner {
      padding: 60px 100px 70px 70px;

      @media (max-width: $pcSmall) {
        padding: 36px 60px 46px 77px;
      }

      @media (max-width: $mobile) {
        padding: 18px 0px 18px 19px;
      }
    }

    > svg {
      @media (max-width: $mobile) {
        width: 110%;
      }
    }
  }
}
