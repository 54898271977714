// ==================== VERIFICATION-SENT ============================================================

.verification-sent__container {
  justify-content: center;

  @media (max-width: $tabletNew) {
    gap: 5px;
    padding-top: 15px;
  }

  @media (max-width: $mobileSmall) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .verification-sent__text-wrap {
    @media (max-width: $tabletNew) {
      padding-top: 80px;
      width: 370px;
    }

    @media (max-width: $mobileSmall) {
      padding-top: 0px;
      width: 200px;
      gap: 10px;
      line-height: 100%;
    }

    .verification-sent__text {
      @media (max-width: $mobileSmall) {
        font-size: 16px;
        line-height: 100%;
      }
    }
  }

  .verification-sent__message-wrap {
    position: absolute;
    right: 0%;
    top: 25%;
    width: 175px;

    @media (max-width: $tabletNew) {
      width: 150px;
      top: 18%;
    }

    @media (max-width: $mobileSmall) {
      width: 90px;
      height: 78px;
    }
  }

  .verification-sent__message-msg {
    position: absolute;
    right: 3%;
    top: 30%;
    white-space: pre-line;
    color: #4d4d4d;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media (max-width: $tabletNew) {
      font-size: 20px;
      top: 23%;
    }

    @media (max-width: $mobileSmall) {
      font-size: 13px;
      right: 3%;
      top: 26%;
    }
  }
}