.plans {
  .landing & {
    background-color: $mainColor;
  }
  &__line {
    z-index: 1;
    position: relative;
    min-height: 40px;
    width: 100%;
    background-image: url("../images/background/plans_bg.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: $mobileSmall) {
      min-height: 65px;
    }
    @media (min-width: $mobile) {
      min-height: 80px;
    }
    @media (min-width: $tablet) {
      min-height: 100px;
    }
    @media (min-width: $pcSmall) {
      min-height: 155px;
    }
    @media (min-width: 1925px) {
      background-size: cover;
      min-height: 250px;
    }
  }

  svg {
    max-width: 150px;
    @media (min-width: $mobileSmall) {
      max-width: 180px;
    }
    @media (min-width: 585px) {
      max-width: 230px;
    }
    @media (min-width: $mobile) {
      max-width: 350px;
    }
    @media (min-width: $tablet) {
      max-width: 430px;
    }
    @media (min-width: $pcSmall) {
      max-width: none;
    }
  }

  &__container {
    display: grid;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1260px;
    .landing & {
      padding-top: 0;
      padding-bottom: 0;
      @media (min-width: 1925px) {
        padding-bottom: 50px;
      }
    }
    @media (min-width: $mobile) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media (min-width: $pcSmall) {
      padding-top: 120px;
      padding-bottom: 130px;
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    color: $colorBlueCrayola;
    margin-top: 0;
    padding-bottom: 15px;
    @media (min-width: $mobileSmall) {
      padding-bottom: 35px;
      margin-top: -70px;
    }

    @media (min-width: 1925px) {
      margin-top: 50px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 245px;
    z-index: 2;
    @media (min-width: $mobileSmall) {
      min-height: 255px;
    }
    @media (min-width: 585px) {
      min-height: 277px;
    }
    @media (min-width: $mobile) {
      min-height: 550px;
    }
    @media (min-width: $tablet) {
      min-height: 590px;
    }
    @media (min-width: $pcSmall) {
      min-height: 720px;
    }
  }

  &__item {
    cursor: pointer;
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;

    &:nth-child(1) {
      align-self: center;
      order: 1;
    }

    &:nth-child(2) {
      align-self: center;
      order: 3;
    }

    &:nth-child(3) {
      align-self: flex-end;
      order: 2;
    }
  }

  &__text {
    color: $colorBlueCrayola;

    &-color {
      color: $colorPurple;
    }
    padding-top: 35px;
    @media (min-width: $pcSmall) {
      padding-top: 0;
    }
  }

  &__inner {
    display: grid;
    justify-items: center;
    gap: 15px;
    color: $mainColor;
    @media (max-width: $mobile) {
      gap: 5px;
    }
  }

  &__inner-without-croussed-out-price {
    padding-bottom: 64px;
    @media (max-width: $mobile) {
      padding-bottom: 30px;
    }
  }

  &__month {
    display: grid;
    text-transform: none;
  }

  &__numder {
    text-shadow: -1px 3px 8px rgba($colorNumber, 0.69);
  }

  &__price {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: rotate(170deg);
      border-bottom: 3px dashed $colorPurple;
    }
  }

  &__new {
    color: $colorPurple;
  }
  &__wrap {
    position: relative;
  }
  &__body {
    img {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      @media (min-width: $mobileSmall) {
        top: 85%;
      }
      @media (min-width: $mobile) {
        top: 108%;
      }
      @media (min-width: $tablet) {
        top: 100%;
      }
    }
    &-big {
      @media (max-width: $tablet) {
        display: none;
      }
      @media (min-width: $tablet) {
        max-width: 568px;
      }
      @media (min-width: $pcSmall) {
        max-width: 700px;
      }
    }

    &-small {
      max-width: 250px;
      @media (min-width: $mobileSmall) {
        max-width: 300px;
      }
      @media (min-width: $mobile) {
        max-width: 500px;
      }
      @media (min-width: $tablet) {
        display: none;
      }
    }
  }
}
//==========================================================================================================================================

.discount {
  margin-bottom: 25px;
  color: $colorPurple;
  display: grid;
  justify-items: center;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 90px;
    max-height: 95px;
    top: 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::before {
    left: 0;
    background-image: url("../images/access/plans_left.png");
    background-position: left top;
  }
  &::after {
    right: 0;
    background-image: url("../images/access/plans_right.png");
    background-position: right top;
  }
  @media (min-width: $mobileSmall) {
    &::before {
      left: 14%;
    }
    &::after {
      right: 16%;
    }
  }
  @media (min-width: $mobile) {
    &::before,
    &::after {
      max-width: 150px;
      max-height: 175px;
    }
  }
  @media (min-width: $tablet) {
    &::before {
      left: 20%;
    }
    &::after {
      right: 22%;
    }
  }
  @media (min-width: $pcSmall) {
    &::before {
      left: 28%;
    }
    &::after {
      right: 30%;
    }
  }
  &__images {
    max-width: 113px;
    @media (min-width: $mobile) {
      max-width: 207px;
    }
    @media (min-width: $pcSmall) {
      max-width: 275px;
    }
  }

  &__text {
    &:not(:last-child) {
      padding-bottom: 20px;
    }
  }
}
