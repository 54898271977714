.circles {
  width: 111px;
  min-height: 16px;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($color: $darkColor, $alpha: 0.34);
  border-radius: 11px;
  @media (min-width: $mobile) {
    width: 211px;
    min-height: 26px;
    gap: 5px;
  }
  .profile-child__link & {
    gap: 2px;
    min-height: 12px;
    width: 75px;
  }
}

.circle {
  display: flex;
  border: 0.5px solid rgba($color: $darkColor, $alpha: 0.28);
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: $mainColor;
  @media (min-width: $mobile) {
    width: 22px;
    height: 22px;
  }
  &.white-circles {
    background-color: $mainColor;
  }
  .profile-child__link & {
    width: 7px;
    height: 7px;
  }
  &__name {
    display: flex;
    align-items: center;
    gap: 5px;
    .child__inner & {
      display: none;
    }
    .profile-child__link & {
      display: none;
    }
  }

  &__text {
    color: $darkColor;
    text-transform: lowercase;
    background-color: $mainColor;
  }
}

.reached ~ .circle {
  background-color: $mainColor;
}
.ruby {
  background-color: $ruby;
}
.amber {
  background-color: $amber;
}
.tigers-eye {
  background-color: $tigersEye;
}
.emerald {
  background-color: $emerald;
}
.aquamarine {
  background-color: $aquamarine;
}
.sapphire {
  background-color: $sapphire;
}
.purple {
  background-color: $purple;
}
