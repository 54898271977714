.lesson-page {
  &__container {
    padding-top: 46px;
    padding-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: $mobile) {
      padding-top: 55px;
      padding-bottom: 55px;
    }
  }

  // &__breadcrumbs {
  // }

  &__subtitle {
    width: 100%;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 12px;
    @media (min-width: $mobile) {
      padding-top: 50px;
      padding-bottom: 22px;
    }
  }

  &__materials {
    padding-bottom: 42px;
    @media (min-width: $mobile) {
      padding-bottom: 62px;
    }
  }

  &__finish {
  }

  &__inner {
    width: 100%;
    position: relative;
  }

  &__video:not(:empty) {
    min-width: 310px;
    max-width: 1488px;
    max-height: 839px;
    min-height: 175px;
    > div {
      border-radius: 30px;
    }
  }

  &__access {
    display: grid;
    justify-items: center;
    gap: 22px;
    padding: 35px;
    background-image: url("../images/background/bg_pink.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @media (min-width: $mobile) {
      background-size: cover;
      padding: 70px;
    }
  }

  // &__text {
  // }

  &__description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 50px;
    @media (min-width: $mobile) {
      padding-bottom: 124px;
    }
  }

  // &__favorite {
  // }

  &__courses {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    @media (min-width: $mobile) {
      gap: 32px;
    }
  }
}

//==========================================================================================================================================

.parents {
  &__container {
    display: grid;
    color: $darkColor;
    padding-bottom: 150px;
    padding-top: 30px;
    @media (min-width: $mobile) {
      padding-bottom: 200px;
      padding-top: 50px;
    }
  }

  &__title {
    color: rgba($color: $darkColor, $alpha: 0.7);
    margin-bottom: 20px;
    margin-top: 20px;
    @media (min-width: $mobile) {
      margin-bottom: 28px;
      margin-top: 46px;
    }
    @media (min-width: $tablet) {
      margin-bottom: 36px;
      margin-top: 57px;
    }
  }

  &__text {
    max-width: 900px;

    p {
      &:not(:last-child) {
        padding-bottom: 10px;
      }
    }
  }

  iframe {
    width: 100%;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px rgba($color: $darkColor, $alpha: 0.25);

    @media (max-width: $mobile) {
      height: 100%;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 32px;
    padding-top: 38px;
  }
}

//==========================================================================================================================================
.card {
  width: 100%;
  position: relative;
  max-width: 152px;

  display: grid;
  padding: 13px;
  align-items: flex-start;
  justify-items: flex-start;
  gap: 5px;
  border-radius: 20px;
  background-color: $mainColor;

  box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: 0.25);

  transition: box-shadow 0.3s ease 0s;

  @media (min-width: $mobile) {
    max-width: 258px;
    padding: 21px;
  }

  @media (min-width: $pcSmall) {
    max-width: 345px;
    padding: 28px;
    gap: 10px;
  }

  &:hover {
    box-shadow: 0 1px 4px 0px rgba($color: #000000, $alpha: 0.25);
  }

  &.lesson-card {
    @media (min-width: $pcSmall) {
      min-height: 355px;
    }
  }

  &-parents {
    color: $colorPinkAmaranth;
    box-shadow: 0 4px 4px 0 rgba($darkColor, 0.25);
  }

  &__link {
    border-radius: 5px;
    display: grid;
    justify-items: flex-start;
    align-items: flex-end;
    gap: 2px;
    @media (min-width: $mobile) {
      gap: 4px;
    }
    @media (min-width: $pcSmall) {
      gap: 16px;
      min-height: 300px;
      min-width: 289px;
    }
  }

  &__boby {
    width: 100%;
    display: grid;
    align-items: flex-start;
    justify-items: flex-start;
    text-align: left;
    gap: 10px;

    .card-course & {
      justify-content: flex-start;
      gap: 0;
    }
  }

  &__img {
    max-width: 126px;
    max-height: 118px;
    width: 100%;

    @media (min-width: $mobile) {
      max-width: 215px;
      max-height: 201px;
    }

    @media (min-width: $pcSmall) {
      max-width: 289px;
      max-height: 270px;
    }
  }

  &__title {
    color: $colorPinkAmaranth;
    .card-course & {
      min-height: 50px;
      @media (max-width: $mobile) {
        min-height: 30px;
      }
    }
  }
  &__text {
    text-transform: lowercase;
  }
  // &__time {
  // }

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;

    .lesson-card & {
      @media (min-width: $pcSmall) {
        gap: 10px;
      }
    }

    .card-course & {
      display: grid;
      grid-template-columns: 1fr 22px;
      align-items: center;
      gap: 20px;

      @media (min-width: $mobile) {
        grid-template-columns: 1fr 50px;
        gap: 20px;
        align-items: center;
      }

      @media (min-width: $pcSmall) {
        gap: 76px;
      }
    }
  }

  &__item {
    color: $mainColor;
    background-color: $colorBlueCrayola;
    padding: 2px 3px;
    border-radius: 7px;
    border: 1px solid transparent;

    @media (min-width: $mobile) {
      padding: 3px 6px;
    }
  }
}

.finished {
  position: relative;

  &__svg {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 94px;
    max-height: 94px;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);

    @media (min-width: $mobile) {
      max-width: 166px;
      max-height: 166px;
    }

    @media (min-width: $pcSmall) {
      max-width: 226px;
      max-height: 226px;
    }
  }
}

.close-lesson {
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 6;
  }
}

//==========================================================================================================================================
.star {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colorBlueCrayola;
  background-color: $mainColor;
  border-color: $colorBlueCrayola;

  @media (min-width: $mobile) {
    max-height: 27px;
    gap: 2px;
  }

  @media (max-width: $mobile) {
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

//==========================================================================================================================================

.time {
  color: $colorPinkAmaranth;
  padding: 0 6px;
  border-radius: 8px;
  border: 2px solid $colorPinkAmaranth;
  background-color: transparent;

  @media (min-width: $mobile) {
    padding: 0 8px;
  }
}

//==========================================================================================================================================
.catalog-card {
  width: 100%;
  display: flex;
  column-gap: 10px;
  row-gap: 22px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  padding-top: 31px;

  @media (min-width: $mobile) {
    column-gap: 26px;
    row-gap: 50px;
    padding-top: 49px;
  }

  @media (min-width: $pcSmall) {
    column-gap: 19px;
    row-gap: 51px;
    padding-top: 57px;
  }

  &-course {
    justify-content: center;
  }
}

//==========================================================================================================================================
.favorite {
  height: 34px;
  cursor: pointer;
  position: absolute;
  height: 20px;
  top: 8%;
  right: 14%;

  @media (min-width: $mobile) {
    height: 25px;
  }

  @media (min-width: $tablet) {
    height: 34px;
  }

  &-lesson {
    position: relative;
    top: 0;
    right: 0;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .favorite__text {
        opacity: 1;
        background-color: rgba($colorBlueCrayola, 0.9);
      }

      .favorite__icon {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  &__text {
    height: 20px;
    padding: 5px;
    color: $mainColor;
    opacity: 0;
    background-color: transparent;
    border-radius: 5px 0 0 5px;
    transition: all 0.6s ease 0s;

    @media (min-width: $mobile) {
      height: 25px;
      padding: 2px;
    }

    @media (min-width: $tablet) {
      height: 34px;
      padding: 8px;
    }

    .favorite-lesson & {
      min-width: 70px;
      opacity: 1;
      background-color: rgba($colorBlueCrayola, 0.9);

      @media (min-width: $mobile) {
        min-width: 110px;
      }

      @media (min-width: $tablet) {
        min-width: 120px;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba($colorBlueCrayola, 0.9);

    @media (min-width: $mobile) {
      height: 25px;
      width: 25px;
    }

    @media (min-width: $tablet) {
      width: 34px;
      height: 34px;
    }

    .favorite-lesson & {
      border-radius: 0 5px 5px 0;
    }
  }
}

.active {
  & .favorite__icon {
    path {
      fill: $mainColor;
    }
  }
}

//==========================================================================================================================================

.breadcrumbs {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: $colorBlueLink;
  &.lesson-page__breadcrumbs {
    padding-top: 5px;
    @media (min-width: $mobile) {
      padding-top: 7px;
    }
    @media (min-width: $pcSmall) {
      padding-top: 16px;
    }
  }
  &__link {
    white-space: nowrap;
    transition: all 0.8s ease 0s;
  }

  &__bar {
    @media (min-width: $mobile) {
      margin-left: 20px;
    }
  }

  &__active {
    color: $colorPinkAmaranth;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//==========================================================================================================================================

.materials-lesson {
  width: 100%;
  display: grid;
  justify-items: start;
  gap: 22px;
  color: $darkColor;
  text-align: left;

  li {
    list-style: disc;
    margin-left: 16px;
  }
}
