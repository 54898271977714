.motivation-landing {
  position: relative;
  background-image: url("../images/background/motivation_bg.svg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  @media (max-width: $mobile) {
    background-image: url("../images/background/motivation_small.svg");
    background-size: auto;
  }

  @media (min-width: 1925px) {
    background-color: $backgPink;
    background-image: none;
  }

  &__container {
    display: grid;
    justify-items: center;
    position: relative;
    padding-bottom: 250px;
    padding-top: 145px;
    @media (max-width: $mobile) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: $mobile) {
      padding-bottom: 195px;
    }

    @media (min-width: $tablet) {
      padding-top: 250px;
    }

    @media (min-width: $pcSmall) {
      padding-top: 225px;
    }
  }

  &__box {
    padding: 2em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding-top: 100px;
    @media (min-width: $mobile) {
      padding: 4em;
      padding-top: 140px;
    }
    @media (min-width: $pcSmall) {
      padding-top: 240px;
    }
  }

  &__title {
    max-width: 250px;
    color: $mainColor;
    z-index: 2;

    @media (min-width: $mobile) {
      max-width: 560px;
    }

    @media (min-width: $tablet) {
      max-width: 600px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $mobile) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      padding-bottom: 35px;
    }

    @media (min-width: $mobile) {
      padding-top: 80px;
      padding-bottom: 0;
    }

    @media (min-width: $tablet) {
      padding-top: 240px;
      padding-bottom: 210px;
      max-width: 854px;
    }
    @media (min-width: $pcSmall) {
      padding-bottom: 155px;
      max-width: 1181px;
    }
  }

  &__item {
    display: grid;
    justify-items: center;
    gap: 25px;
    &:last-child {
      & .motivation-landing__text {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 45px;
          height: 135px;
          top: 70px;
          right: -50px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
          background-image: url("../images/motivation/arrow_right_small.png");
          z-index: 3;
          @media (min-width: $mobile) {
            width: 75px;
            height: 225px;
            top: 125px;
            right: 0;
          }
          @media (min-width: $tablet) {
            background-image: url("../images/motivation/arrow_right_mid.png");
            width: 111px;
            height: 330px;
            top: 75px;
            right: -50px;
          }
          @media (min-width: 1021px) {
            right: -80px;
          }
          @media (min-width: $pcSmall) {
            width: 74px;
            height: 220px;
            top: 66px;
            right: -40px;
          }
          @media (min-width: 1391px) {
            background-image: url("../images/motivation/arrow_right.png");
            width: 111px;
            height: 280px;
            top: 50px;
            right: -115px;
          }
        }
      }
    }
  }

  &__images {
    max-width: 220px;

    @media (min-width: $tablet) {
      max-width: 250px;
    }

    @media (min-width: $pcSmall) {
      max-width: 342px;
    }
  }

  &__text {
    max-width: 300px;
    color: $colorMotiv;

    @media (max-width: $pcSmall) {
      padding-left: 30px;
    }

    @media (max-width: $tablet) {
      max-width: 212px;
      padding-left: 20px;
    }

    @media (max-width: $mobile) {
      padding-left: 0;
      max-width: 240px;
      text-align: center;
    }
  }
  &__decorate {
    position: absolute;
    @media (max-width: $mobile) {
      width: 100%;
      height: 395px;
      background-position: bottom;
      background-image: url("../images/background/motiv_bg.svg");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  &__wrap {
    position: relative;
    display: grid;
    align-items: center;
    padding-top: 80px;

    padding-bottom: 0;

    @media (max-width: $mobile) {
      width: 100%;
      justify-items: center;
    }

    @media (min-width: $mobile) {
      padding-bottom: 90px;
      padding-top: 235px;
    }

    @media (min-width: $tablet) {
      padding-bottom: 77px;
      padding-top: 200px;
    }
    @media (min-width: $pcSmall) {
      padding-bottom: 255px;
      padding-top: 20px;
    }
  }
  &__toy {
    position: relative;
    z-index: 2;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -(calc(224px + 80px));
      width: 224px;
      height: auto;
      z-index: 2;
      @media (max-width: $pcSmall) {
        max-width: 170px;
        right: -(calc(170px + 80px));
      }
      @media (max-width: $tablet) {
        max-width: 150px;
        right: -(calc(150px + 10px));
      }
      @media (max-width: $mobile) {
        max-width: 79px;
        right: -(calc(79px + 3px));
      }
    }
  }
  &__promo {
    position: relative;
    color: $colorBlueCrayola;
    text-transform: none;
    text-align: center;
    max-width: 205px;
    &::after {
      content: "";
      position: absolute;
      width: 54px;
      height: 120px;
      top: 10px;
      left: -60px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      background-image: url("../images/motivation/arrow_left_small.png");
      z-index: 3;
    }
    @media (min-width: $mobile) {
      max-width: 420px;
      &::after {
        width: 110px;
        height: 240px;
        top: 40px;
        left: -85px;
      }
    }
    @media (min-width: $tablet) {
      max-width: 410px;
      &::after {
        background-image: url("../images/motivation/arrow_left.png");
        height: 263px;
        top: 24px;
        left: -151px;
      }
    }
    @media (min-width: $pcSmall) {
      max-width: 555px;
      &::after {
        width: 140px;
        height: 340px;
        top: 24px;
        left: -160px;
      }
    }
    @media (min-width: 1925px) {
      color: $mainColor;
    }
  }

  &__file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $pcSmall) {
      padding-top: 90px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 120px;
      max-height: 120px;
      top: 13%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
    }

    &::after {
      right: -15%;
      background-image: url("../images/expectation/cloud.png");
      max-width: 80px;
      max-height: 80px;
    }

    &::before {
      left: -15%;
      background-image: url("../images/expectation/sun.png");
      max-width: 60px;
      max-height: 60px;
      top: 95%;
    }

    @media (min-width: $mobile) {
      &::before,
      &::after {
        max-width: 120px;
        max-height: 120px;
      }

      &::after {
        right: -20%;
        top: -5%;
      }

      &::before {
        top: 80%;
        left: -20%;
      }
    }
    @media (min-width: $tablet) {
      &::after {
        right: -30%;
        max-width: 200px;
        max-height: 200px;
        top: -5%;
      }

      &::before {
        left: -30%;
        max-width: 180px;
        max-height: 180px;
        top: -5%;
      }
    }

    @media (min-width: $pcSmall) {
      &::after {
        right: -43%;
      }

      &::before {
        left: -50%;
      }
    }
  }

  &__video {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    max-width: 300px;
    padding: 10px 20px 20px 15px;

    @media (min-width: $mobile) {
      max-width: 510px;
      padding: 25px 25px 30px 30px;
    }

    @media (min-width: $tablet) {
      max-width: 640px;
      padding: 24px 30px 48px 48px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: -5px;
      background-image: url("../images/motivation/poster_decor.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      z-index: 2;
      @media (min-width: $pcSmall) {
        top: -5px;
      }
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    color: $mainColor;
    display: grid;
    justify-items: center;
    gap: 38px;
    z-index: 2;
    @media (max-width: $mobileSmall) {
      &::before {
        content: "";
        position: absolute;
        width: 110%;
        height: 100%;
        min-height: 311px;
        top: -194px;
        left: -24px;
        background-image: url("../images/motivation/line_video.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        z-index: 6;
      }
    }
    @media (max-width: $mobile) {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 50px;
    }

    @media (min-width: $mobile) {
      gap: 45px;
      padding-top: 40px;
    }

    @media (min-width: $tablet) {
      gap: 60px;
      padding-bottom: 150px;
      padding-top: 65px;
    }

    @media (min-width: $pcSmall) {
      padding-bottom: 85px;
    }
  }

  &__description {
    text-align: center;
    max-width: 280px;
    @media (min-width: $mobile) {
      max-width: 438px;
    }
    @media (min-width: $tablet) {
      max-width: 516px;
    }
  }
  // &__lama {
  // }
}
//==========================================================================================================================================


.childs__collage_img {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    max-width: 300px;
    padding: 10px 20px 20px 15px;

    @media (min-width: $mobile) {
      max-width: 510px;
      padding: 25px 25px 30px 30px;
    }

    @media (min-width: $tablet) {
      max-width: 640px;
      padding: 24px 30px 48px 48px;
    }
}

.lama {
  position: absolute;
  bottom: -10px;
  width: 100%;

  svg {
    z-index: 2;
    position: relative;
    @media (min-width: 2000px) {
      display: none;
    }
  }
  &__img {
    position: absolute;
    bottom: 61px;
    right: 1%;
    max-width: 280px;
    @media (min-width: $mobile) {
      max-width: 350px;
      bottom: 59px;
    }
    @media (min-width: $tablet) {
      max-width: 550px;
      bottom: 50px;
    }
    @media (min-width: 1100px) {
      bottom: 55px;
    }
    @media (min-width: $pcSmall) {
      max-width: 550px;
      bottom: 45px;
    }
    @media (min-width: 1390px) {
      max-width: 627px;
    }
    //@media (max-width: 550px) {
    //  display: none;
    //}
    &-big {
      @media (max-width: 550px) {
        display: none;
      }

      @media (min-width: $pcSmall) {
        display: none;
      }
    }

    &-small {
      display: none;

      @media (min-width: $pcSmall) {
        display: block;
        height: 600px;
      }
    }

    &-mobile {
      //display: none;

      @media (max-width: 550px) {
        //display: block;
        height: 150px;
      }

      @media (min-width: 550px) {
        display: none;
      }
    }
  }
}

//==========================================================================================================================================

.video {
  position: relative;
  z-index: 3;
  width: 263px;

  @media (min-width: $mobile) {
    width: 452px;
  }

  @media (min-width: $tablet) {
    width: 560px;
  }
  > div {
    border-radius: 30px;
  }
}
