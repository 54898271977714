.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  border-radius: 54px;
  background-color: $colorBlueLink;
  text-transform: uppercase;
  color: $mainColor;
  transition: all 0.6s ease 0s;
  z-index: 10;
  @media (min-width: $mobile) {
    padding: 17px 24px;
  }
  &:hover {
    background-color: $colorBlueShadow;
    box-shadow: none;
  }
  .header & {
    padding: 7px 20px;
  }
  .buy-toy__buttons & {
    min-width: 49px;
    padding: 7px 0;
    @media (min-width: $mobile) {
      min-width: 111px;
      padding: 17px 0;
    }
  }
  &-user {
    justify-self: center;
    width: max-content;
    text-transform: uppercase;
    border-radius: 54px;
    background-color: $colorBlue;
    box-shadow: 0 4px 4px 0 $colorBlueShadow;
  }
  &__text {
    min-height: 24px;
    line-height: 120%;
    text-decoration-line: underline;
    text-transform: lowercase;
    @media (min-width: $mobile) {
      min-height: 28px;
    }
    @media (min-width: $tablet) {
      min-height: 35px;
    }
  }
  &-border {
    min-width: 62px;
    border-radius: 23px;
    text-transform: capitalize;
    padding: 3px 24px;
    border: 2px solid $colorPinkAmaranth;
    transition: all 0.6s ease 0s;
    background-color: transparent;
    color: $colorPinkAmaranth;

    .page-header__top & {
      color: $mainColor;
      border: 2px solid $mainColor;
    }

    &:hover {
      background-color: transparent;
      color: $colorBlueShadow;
      border-color: $colorBlueShadow;
    }
  }

  &-outline {
    min-width: 196px;
    text-transform: lowercase;
    padding: 3px 24px;
    text-decoration-line: underline;
    background-color: transparent;
    color: $mainColor;
    .page-header__wrap & {
      // color: $colorPinkAmaranth;
      color: inherit;
    }
    &:hover {
      // color: $colorPinkAmaranth;
      color: $darkColor;
      background-color: transparent;
    }
  }

  &-underline {
    justify-content: flex-start;
    padding: 0;
    border-radius: none;
    background-color: transparent;
    text-transform: none;
    .menu & {
      padding: 0;
      color: $colorBlueCrayola;

      white-space: nowrap;
      &.active {
        color: $colorPink;
      }
    }
    .footer & {
      color: $mainColor;
    }
    .page-header__lessons & {
      color: $colorBlueLink;
      white-space: nowrap;
    }
    &:hover {
      color: $colorPink;
      background-color: transparent;
      .page-header__profile & {
        color: $mainColor;
        text-shadow: 0 4px 4px rgba($color: $colorPurpleShadow, $alpha: 0.6);
      }
    }
  }
  &-color {
    justify-self: center;
    border-radius: 54px;
    background-color: $colorPurple;
    box-shadow: 0 4px 4px 0 $colorPinkAmaranth;

    .plans & {
      margin-top: 42px;
      @media (min-width: $mobile) {
        margin-top: 70px;
      }
    }
  }

  &.hidden {
    display: none;
  }
  &.finish-lesson {
    display: block;
    position: absolute;
    top: -3em;
    right: 0;
    z-index: 5;
  }
  .toy & {
    width: 99px;
    padding: 8px 0;
    @media (min-width: $mobile) {
      width: 200px;
      padding: 18px 0;
    }
  }
}

input[type="submit"].button {
  position: absolute;

  bottom: -50%;
  cursor: pointer;
  justify-self: center;
  border-radius: 54px;
  text-transform: uppercase;
  font-weight: 800;

  padding: 9px 12px;
  min-width: 100px;
  background-color: $colorPinkAmaranth;
  box-shadow: 0 4px 4px 0 $colorButtonShadow;
  @media (min-width: $mobile) {
    padding: 14px 26px;
  }
  @media (min-width: $tablet) {
    bottom: -70%;
  }
  &:hover {
    box-shadow: none;
  }
}

.landing {
  .button__up {
    max-width: max-content;
    font-weight: 800;
  }
}
