.choice-landing {
  position: relative;

  &__container {
    padding-top: 55px;
    padding-bottom: 15px;
    display: grid;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile) {
      padding-top: 0;
    }

    @media (min-width: $mobile) {
      padding-bottom: 110px;
    }
    @media (min-width: $tablet) {
      padding-bottom: 170px;
    }
  }

  &__title {
    &:not(:last-child) {
      padding-bottom: 40px;

      @media (min-width: $mobile) {
        padding-bottom: 100px;
      }

      @media (min-width: $tablet) {
        padding-bottom: 125px;
      }
    }
  }

  &__wrapper {
    display: grid;
    gap: 50px;
    z-index: 2;
    @media (min-width: $mobileSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $tablet) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 80px;
    }
  }

  &__images {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: -20%;
    bottom: -14%;

    @media (min-width: $mobileSmall) {
      bottom: -31%;
      left: -5%;
    }
    @media (min-width: $mobile) {
      bottom: -17%;
      left: 0;
    }
    @media (min-width: $tablet) {
      bottom: -35%;
      left: -10%;
    }

    @media (min-width: $pcSmall) {
      bottom: -40%;
      left: -5%;
    }

    &-big {
      @media (max-width: $tablet) {
        display: none;
      }

      @media (min-width: $tablet) {
        max-width: 650px;
      }

      @media (min-width: $pcSmall) {
        max-width: 811px;
      }

      @media (min-width: 1925px) {
        display: none;
      }
    }
    &-small {
      max-width: 330px;
      max-width: 366px;
      @media (min-width: $mobile) {
        max-width: 500px;
      }
      @media (min-width: $tablet) {
        display: none;
      }
    }
  }
}

//==========================================================================================================================================

.choise {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 34px;
  position: relative;
  width: 213px;
  height: 230px;

  padding: 10px 26px;
  border-radius: 50px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $colorPinkAmaranth;
  background-color: $mainColor;
  box-shadow: 5px 7px 4px 0 rgba($colorCandyPink, 0.64);

  @media (min-width: $mobile) {
    width: 284px;
    height: 351px;
    padding: 17px 32px 35px 32px;
    gap: 65px;
  }

  @media (min-width: $pcSmall) {
    width: 296px;
    min-height: 373px;
    gap: 75px;
  }

  img {
    position: absolute;
    max-width: 90px;
    top: -25px;
    right: -40px;
    z-index: 2;

    @media (min-width: $mobile) {
      top: -40px;
      right: -50px;
      max-width: 138px;
    }

    @media (min-width: $tablet) {
      max-width: 148px;
    }
  }
  &__fon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../images/background/choise_pink.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 103%;

    .choise-center-blue & {
      background-image: url("../images/background/choise_center_blue.svg");
      @media (max-width: $mobile) {
        background-image: url("../images/background/choise_blue_small.svg");
      }
    }
    .choise-center-pink & {
      background-image: url("../images/background/choise_center_pink.svg");
      @media (max-width: $mobile) {
        background-image: url("../images/background/choise_pink_small.svg");
      }
    }
    .choise-blue & {
      background-image: url("../images/background/choise_blue.svg");
      @media (max-width: $mobile) {
        background-image: url("../images/background/choise_blue_small.svg");
      }
    }
    @media (max-width: $mobile) {
      background-image: url("../images/background/choise_pink_small.svg");
    }
  }
  &__title {
    position: relative;
    z-index: 3;
    max-width: 180px;
    color: $mainColor;

    @media (max-width: $mobile) {
      max-width: 140px;
    }
  }

  &__text {
    color: $colorChoice;
  }
}
