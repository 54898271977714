.free-landing {
  position: relative;
  padding-top: 180px;
  padding-bottom: 90px;
  @media (min-width: $mobileSmall) {
    padding-top: 170px;
    padding-bottom: 85px;
  }
  @media (min-width: 592px) {
    padding-top: 250px;
    padding-bottom: 95px;
  }
  @media (min-width: $mobile) {
    padding-top: 316px;
    padding-bottom: 175px;
  }
  @media (min-width: $tablet) {
    padding-top: 245px;
    padding-bottom: 132px;
  }
  @media (min-width: 1100px) {
    padding-top: 370px;
    padding-bottom: 270px;
  }
  @media (min-width: $pcSmall) {
    padding-top: 421px;
    padding-bottom: 202px;
  }

  &__fon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/background/free_bg_middle.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: $pcSmall) {
      background-image: url("../images/background/free_bg.svg");
    }
    @media (min-width: 1925px) {
      background-color: $colorBlue;
    }
  }
  &__container {
    display: flex;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url("../images/access/info_bg_small.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      @media (min-width: $pcSmall) {
        background-image: url("../images/access/info_bg.svg");
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 223px;
    height: 223px;
    background-color: $mainColor;
    border-radius: 50%;
    padding: 40px 0 35px;
    z-index: 2;

    @media (min-width: $mobile) {
      width: 418px;
      height: 418px;
      padding: 88px 0 71px;
    }

    @media (min-width: 1100px) {
      width: 506px;
      height: 506px;
      padding: 109px 0 90px;
    }
  }

  &__title {
    text-align: center;
    color: $colorBlueCrayola;
    &-color {
      color: $colorPurple;
    }
    &-big {
      text-transform: uppercase;
    }
  }
}
