.la-market {
  &__container {
    padding-bottom: 150px;
    padding-top: 30px;
    @media (min-width: $mobile) {
      padding-bottom: 200px;
      padding-top: 55px;
    }
    @media (min-width: $pcSmall) {
      padding-top: 65px;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__images {
    width: 100%;
    max-width: 274px;

    @media (min-width: $mobileSmall) {
      max-width: 420px;
    }

    @media (min-width: $tablet) {
      max-width: 522px;
    }

    @media (min-width: $pcSmall) {
      max-width: 688px;
    }
  }

  // &__title {
  // }

  &__wrap {
    padding-top: 46px;

    @media (min-width: $mobile) {
      padding-top: 94px;
    }
  }
}

//==========================================================================================================================================
.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 40px;

  @media (min-width: $mobile) {
    row-gap: 60px;
    justify-content: flex-start;
  }

  @media (min-width: $tablet) {
    column-gap: 40px;
  }
}

//==========================================================================================================================================

.wish {
  &__container {
    padding-top: 45px;
    padding-bottom: 150px;

    @media (min-width: $mobile) {
      padding-top: 100px;
      padding-bottom: 200px;
    }
  }

  // &__breadcrumbs {
  // }

  &__wrap {
    padding-top: 60px;
    @media (min-width: $tablet) {
      padding-top: 100px;
    }
  }
}

//==========================================================================================================================================
