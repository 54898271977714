.toy {
  position: relative;
  max-width: 157px;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: $mobile) {
    max-width: 342px;
  }

  @media (min-width: $tablet) {
    min-height: 472px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__body {
    padding-bottom: 20px;
    display: grid;
    justify-items: center;
    align-items: flex-start;

    @media (min-width: $mobile) {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  &__img {
    max-width: 212px;

    @media (max-width: $mobile) {
      max-width: 96px;
    }
  }

  &__title {
    text-align: center;
    line-height: 100%;
    padding-bottom: 5px;
    padding-top: 20px;

    @media (min-width: $mobile) {
      padding-bottom: 10px;
    }
  }

  &__text {
    line-height: 100%;
    color: $colorBlueCrayola;
    text-align: center;
    &:not(:last-child) {
      padding-bottom: 16px;

      @media (min-width: $mobile) {
        padding-bottom: 40px;
      }
    }
  }

  &__info {
    position: absolute;
    right: 0;
    align-self: flex-start;
    display: grid;
    gap: 5px;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding-top: 10px;

    @media (min-width: $mobile) {
      padding-top: 40px;
      gap: 10px;
    }
  }

  &__price,
  &__like,
  &__loved {
    display: grid;
    justify-items: center;
    align-items: center;
  }

  .desire & {
    align-items: center;
    min-height: auto;
    max-width: none;
    gap: 5px;

    & .button,
    &__title,
    &__text {
      display: none;
    }

    &__info {
      display: none;
    }

    &__body {
      padding: 0;
    }

    &__img {
      padding-top: 20px;
      max-width: 55px;

      @media (min-width: $mobile) {
        max-width: 114px;
      }
    }
  }

  .my-toy & {
    min-height: auto;

    &__body {
      align-content: center;
      padding-top: 0;
    }

    & .price,
    & .loved {
      visibility: hidden;
      opacity: 0;
    }

    & .button {
      display: none;
    }
  }
}

//==========================================================================================================================================

.loved {
  background-color: $colorBlueCrayola;
  border-radius: 3px;
  width: 16px;
  height: 16px;

  @media (min-width: $mobile) {
    width: 30px;
    height: 30px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile) {
      width: 11px;
      height: 11px;
    }

    &.active {
      path {
        fill: $mainColor;
      }
    }
  }

  @media (min-width: $tablet) {
    & .desire {
      width: 22px;
      height: 22px;

      & svg {
        width: 19px;
        height: 19px;
      }
    }
  }
}

//==========================================================================================================================================

.like {
  svg {
    width: 28px;
    height: 28px;
  }

  @media (max-width: $mobile) {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  // &__text {
  // }
  @media (min-width: $tablet) {
    .desire & {
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }
}

//==========================================================================================================================================

.price {
  color: $colorYellow;

  &__img {
    max-width: 16px;

    @media (min-width: $mobile) {
      max-width: 38px;
    }
  }

  // &__text {
  // }
  .buy-toy & {
    &__img {
      max-width: 27px;

      @media (min-width: $mobile) {
        max-width: 47px;
      }
    }
  }

  @media (min-width: $tablet) {
    .desire & {
      &__img {
        width: 19px;
        height: 19px;
      }
    }
  }
}

//==========================================================================================================================================

.buy-toy {
  &__container {
    padding-top: 70px;
    padding-bottom: 150px;
    @media (min-width: $mobile) {
      padding-bottom: 200px;
    }
  }

  &__body {
    padding-top: 60px;
    display: grid;
    justify-items: center;
    align-items: center;

    gap: 20px;

    @media (min-width: $mobile) {
      grid-template-columns: 357px 1fr;
    }

    @media (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__img {
    width: 436px;
    height: 463px;

    @media (max-width: $tablet) {
      width: 357px;
      height: 379px;
    }

    @media (max-width: $mobile) {
      width: 202px;
      height: 216px;
    }
  }

  &__info {
    display: grid;
    justify-items: center;
    justify-content: center;
  }

  &__title {
    &:not(:last-child) {
      @media (min-width: $mobile) {
        padding-bottom: 20px;
      }

      @media (min-width: $tablet) {
        padding-bottom: 30px;
      }
    }

    &-small {
      padding-bottom: 0;
    }
  }

  &__subtitle {
    color: $colorBlueCrayola;
    text-align: center;
    max-width: 175px;
    padding-bottom: 10px;
    text-transform: none;

    @media (min-width: $mobile) {
      max-width: 328px;
      padding-bottom: 20px;
    }

    @media (min-width: $tablet) {
      padding-bottom: 30px;
    }

    &-small {
      padding-bottom: 0;
    }
  }

  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-bottom: 10px;

    @media (min-width: $mobile) {
      padding-bottom: 20px;
    }

    @media (min-width: $tablet) {
      padding-bottom: 30px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & .button-color {
      box-shadow: none;
    }
  }

  &__link-ar {
    position: absolute;
    bottom: -5px;
    right: 0;

    & svg {
      @media (max-width: $tablet) {
        width: 50px;
        height: 50px;
      }

      @media (max-width: $mobile) {
        width: 31px;
        height: 31px;
      }
    }
  }
}
