.progress-bar {
  position: relative;
  min-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: $colorPinkAmaranth;
  border-radius: 50%;
  color: $mainColor;
  line-height: 120%;
  flex: 0 0 22px;
  @media (min-width: $mobile) {
    min-height: 40px;
    max-width: 40px;
    flex: 0 0 40px;
  }
  @media (min-width: $tablet) {
    flex: 0 0 50px;
    max-width: 50px;
    min-height: 50px;
  }
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    & path:nth-child(1) {
      stroke: $colorPinkAmaranth;
    }
    & path:nth-child(2) {
      stroke: $mainColor;
    }
  }
}
// .progress {
//   &__all {
//   }
// }
//==========================================================================================================================================

.course {
  &__description {
    width: 100%;
    display: grid;
    justify-items: start;
    gap: 5px;
    color: $darkColor;
    text-align: left;
    &:not(:empty) {
      padding-top: 25px;
    }

    li {
      list-style: disc;
      margin-left: 16px;
    }
    @media (min-width: $mobile) {
      &:not(:empty) {
        padding-top: 40px;
      }
    }
    @media (min-width: $pcSmall) {
      &:not(:empty) {
        padding-top: 44px;
      }
    }
  }
}
//==========================================================================================================================================
