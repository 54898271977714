.footer {
  background-color: $colorBlue;

  &__container {
    padding-top: 25px;
    padding-bottom: 45px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    color: $mainColor;
    @media (max-width: $pcSmall) and (min-width: $mobile) {
      padding-left: 79px;
      padding-right: 79px;
    }
    @media (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__logo {
    img {
      display: none;
      @media (min-width: $tablet) {
        display: block;
        max-width: 67px;
      }
      @media (min-width: $pcSmall) {
        max-width: 99px;
      }
    }
    @media (max-width: $tablet) {
      order: 1;
    }
  }
  &__text {
    @media (min-width: $tablet) {
      display: none;
    }
  }
  &__menu {
    @media (max-width: $tablet) {
      order: 2;
    }
  }
  &__contact {
    @media (max-width: $tablet) {
      order: 3;
    }
  }

  &__feedback {
    @media (max-width: $tablet) {
      order: 4;
    }
  }
}
//==========================================================================================================================================

.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (min-width: $tablet) {
    gap: 10px;
  }
  &__item {
    white-space: nowrap;
  }
}
//==========================================================================================================================================

.feedback {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;

  &__inner {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    @media (min-width: $tablet) {
      flex-direction: column;
    }
  }
}
