.page-header {
  position: relative;
  background-position: center bottom;
  background-size: cover;
  height: 216px;
  background-image: url("../images/background/menu_bg.svg");

  @media (min-width: $mobile) {
    height: 280px;
  }

  @media (min-width: $tablet) {
    height: 373px;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    position: relative;
    padding: 1em 2em;

    & > svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    color: $colorBlueCrayola;
    position: relative;
  }

  &__container {
    padding-top: 25px;

    display: grid;

    justify-items: start;
    align-items: center;

    padding-bottom: 150px;
    .lessons & {
      justify-items: center;
    }
    .button-border {
      display: none;
    }
    .parents-page & {
      padding-bottom: 0;
    }
    @media (min-width: $mobile) {
      padding-bottom: 200px;
    }
    @media (min-width: $mobile) {
      padding-top: 50px;

      .button-border {
        display: flex;
      }
    }
  }
  @media (min-width: $pcSmall) {
    padding-bottom: 250px;
  }
  &__wrap {
    color: rgba($color: $darkColor, $alpha: 0.6);
    padding: 20px 0;
  }

  &__subtitle {
    max-width: 550px;
    text-align: center;

    &:not(:last-child) {
      padding-bottom: 20px;
    }
  }

  &__images {
    max-width: 267px;

    @media (min-width: $mobile) {
      max-width: 586px;
    }

    @media (min-width: $tablet) {
      max-width: 711px;
    }
  }

  &__lessons {
    position: relative;
    width: 100%;

    .lessons & {
      display: grid;
      justify-items: center;
      align-items: center;

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $colorBlue;
        margin-top: 10px;
        @media (min-width: $mobile) {
          margin-top: 18px;
        }
        @media (min-width: $pcSmall) {
          margin-top: 28px;
        }
      }
    }
  }
}

//==========================================================================================================================================

.user {
  color: $mainColor;
  position: relative;
  z-index: 1;
  &__container {
    padding-top: 30px;
    padding-bottom: 60px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 60px;
    @media (min-width: $tablet) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $tablet) {
      padding-top: 55px;
    }
  }

  &__inner {
    margin-top: 28px;

    @media (min-width: $pcSmall) {
      margin-top: 55px;
    }
    &:last-child {
      padding-bottom: 150px;
      @media (min-width: $mobile) {
        padding-bottom: 200px;
      }
    }
  }
}
//==========================================================================================================================================

.bubble {
  padding: 6em;
  width: max-content;
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: $pcSmall) {
    padding: 4.6em;
  }

  @media (max-width: $mobile) {
    padding: 2.7em;
    //  padding: 3.7em;
  }
  &-small {
    padding: 4em;
  }
  &-children {
    &-pink {
      padding: 7.5em 7.5em 7.5em 8.75em;
      @media (max-width: $tablet) {
        padding: 5em 5em 5em 6.25em;
      }
      @media (max-width: $mobile) {
        padding: 3.7em;
      }
    }
    &-blue {
      padding: 7.5em 9.375em 7.5em 7.5em;
      @media (max-width: $tablet) {
        padding: 5em 6.875em 5em 5em;
      }
      @media (max-width: $mobile) {
        padding: 3.7em 4.5em 3.7em 3.7em;
      }
    }
  }
  &-user {
    padding: 3em 5em 6em;
    @media (max-width: $mobile) {
      padding: 2em 3.7em 3.7em;
    }
  }
  &-tariff {
    @media (min-width: 1013px) {
      margin-top: -250px;
    }
  }
  &__wrapper {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    @media (min-width: $tablet) {
      gap: 20px;
    }
    .bubble-user & {
      gap: 10px;
    }
  }
  &__control {
    position: relative;
  }
  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 73px;
      @media (min-width: $mobile) {
        max-width: 97px;
      }
      @media (min-width: $tablet) {
        max-width: 123px;
      }
    }
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 2px;
      left: -1px;
    }
  }

  &__title {
    text-transform: uppercase;
    .bubble-user & {
      &:not(:last-child) {
        @media (min-width: $mobile) {
          margin-bottom: 24px;
        }
      }
    }
    &-user {
      max-width: 350px;
    }
    &-small {
      max-width: 250px;
    }
    ~ a {
      position: absolute;
      top: 0;
      right: -45%;
      @media (max-width: $mobile) {
        top: -38%;
      }
      svg {
        width: 17px;
        height: 17px;
        transition: all 0.6s ease 0s;
        @media (min-width: $mobile) {
          width: 26px;
          height: 26px;
        }
        @media (min-width: $pcSmall) {
          width: 38px;
          height: 38px;
        }
        &:hover {
          fill: $colorPinkAmaranth;
        }
      }
    }
  }

  background-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 18px;
  }

  & > svg {
    @extend background-svg;
  }
}
.bubble-field {
  &__input {
    position: relative;
    padding: 1em 2em;

    & > svg {
      @extend background-svg;
    }
    .form-bubble & {
      padding: 0;
    }
  }

  &__value {
    position: relative;
    color: $darkColor;
    white-space: nowrap;
    padding: 1.2em;
    .bubble-user & {
      padding: 2em;
    }
    .bubble-tariff & {
      padding: 0;
      color: $colorBlueCrayola;
    }
  }
  &__label {
    @media (min-width: $mobile) {
      margin-bottom: 10px;
    }
    @media (min-width: $tablet) {
      margin-bottom: 20px;
    }
    .bubble-user & {
      margin-bottom: 10px;
    }
  }
  &__form {
    position: relative;
    display: grid;
    align-items: center;
    gap: 10px;
    @media (min-width: $mobile) {
      padding: 10px 0;
    }

    .bubble-field__input {
      padding: 0.3em 1em;
    }
  }
  &__gender {
    display: grid;
    justify-items: center;
    & .bubble-field__label {
      margin-bottom: 10px;
    }
  }
}
