input[type="text"] {
  width: max-content;
  text-align: center;
  padding: 15px;
  background-color: transparent;
  color: $darkColor;
  transition: all 0.8s ease 0s;
  outline: none;
  font-weight: 800;

  &::placeholder,
  &:focus::placeholder {
    color: $darkColor;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

input[type="radio"] {
  appearance: none;
  outline: none;
  background-color: transparent;
  vertical-align: middle;

  &:checked {
    &#id_gender_0::after {
      background-image: url("../images/icons/children_boy_checked.png");
    }

    &#id_gender_1::after {
      background-image: url("../images/icons/children_girl_checked.png");
    }

    .bubble-children-blue & {
      &#id_gender_0::after {
        background-image: url("../images/icons/children_boy_checked_blue.png");
      }

      &#id_gender_1::after {
        background-image: url("../images/icons/children_girl_checked_blue.png");
      }
    }
  }

  &.form__gender {
    position: relative;
    width: 38px;
    height: 36px;

    @media (min-width: $mobile) {
      width: 60px;
      height: 58px;
    }

    @media (min-width: $tablet) {
      width: 67px;
      height: 65px;
    }
  }

  &::after {
    content: "";
    position: relative;
    width: 38px;
    height: 36px;
    display: flex;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: $mobile) {
      width: 60px;
      height: 58px;
    }

    @media (min-width: $tablet) {
      width: 67px;
      height: 65px;
    }
  }

  &#id_gender_0::after {
    background-image: url("../images/icons/children_boy.png");
  }

  &#id_gender_1::after {
    background-image: url("../images/icons/children_girl.png");
  }
}

.bubble-field__form label {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  color: transparent;
  font-size: 0;

  &[for="id_gender_0"],
  &[for="id_gender_1"] {
    gap: 0;
  }
}

.form {
  &__gender {
    cursor: pointer;
    display: flex;
    gap: 10px;
  }

  &__data {
    // width: 300px;
    // width: 100%;
    padding: 15px;
    text-align: center;
    font-weight: 800;
    height: 60px;
    background-color: transparent;
    transition: all 0.8s ease 0s;
    outline: none;
    color: $darkColor;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &::-webkit-calendar-picker-indicator {
      background-image: url("../images/icons/calendar.svg");
      opacity: 0.698;
    }
  }
}

//==========================================================================================================================================
.errorlist {
  color: rgb(251, 8, 8);
  padding: 15px 0;
}

//==========================================================================================================================================
