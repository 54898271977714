.social-landing {
  position: relative;
  background-color: $mainColor;
  &__fon {
    min-height: 40px;
    width: 100%;
    background-image: url("../images/background/plans_bg.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: $mobileSmall) {
      min-height: 65px;
    }
    @media (min-width: $mobile) {
      min-height: 80px;
    }
    @media (min-width: $tablet) {
      min-height: 100px;
    }
    @media (min-width: $pcSmall) {
      min-height: 155px;
    }
    @media (min-width: 1925px) {
      background-size: cover;
      min-height: 160px;
    }
  }
  &__container {
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-width: 103px;
      @media (min-width: $mobile) {
        max-width: 120px;
      }
      @media (min-width: $pcSmall) {
        max-width: 149px;
      }
    }
  }

  &__text {
    color: $darkColor;
    margin-top: -5px;
  }

  // &__title {
  // }

  &__list {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    gap: 10px;
    @media (min-width: $mobile) {
      gap: 26px;
    }
    @media (min-width: $pcSmall) {
      gap: 30px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;

    width: 20px;
    height: 20px;
    @media (min-width: $mobile) {
      width: 43px;
      height: 43px;
    }
    @media (min-width: $pcSmall) {
      width: 53px;
      height: 53px;
    }
    svg {
      path {
        transition: all 0.6s ease 0s;
        fill: $colorBlue;
      }
    }
    &:hover svg path {
      fill: $colorPink;
    }
  }
}
