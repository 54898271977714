.finish {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: max-content;
  height: max-content;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  gap: 15px;
  padding: 20px;

  display: grid;
  justify-items: center;
  justify-content: center;
  align-content: center;

  border-radius: 20px;
  background-color: rgba($color: $mainColor, $alpha: 0.91);
  box-shadow: 5px 6px 6px 0 rgba($color: $colorDarkPurple, $alpha: 0.44);
  @media (min-width: $mobile) {
    padding: 40px;
  }
  @media (min-width: $tablet) {
    padding: 55px;
  }
  &.finish-show {
    opacity: 1;
    visibility: visible;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 8%;
    right: 6%;
    @media (min-width: $mobile) {
      width: 30px;
      height: 30px;
    }
    svg {
      width: 10px;
      height: 10px;
      @media (min-width: $mobile) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__title {
    color: $colorPinkAmaranth;
    text-transform: uppercase;
  }

  &__text {
    color: $colorBlueCrayola;
  }

  &__subtitle {
    color: $colorPinkAmaranth;
  }

  &__image {
    max-width: 50px;
    height: auto;
    @media (min-width: $mobile) {
      max-width: 120px;
    }
    @media (min-width: $pcSmall) {
      max-width: 200px;
    }
    &-big {
      max-width: 75px;
      @media (min-width: $tablet) {
        max-width: 120px;
      }
      @media (min-width: $tablet) {
        max-width: 184px;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    & .button-color {
      box-shadow: none;
      min-width: 64px;
    }
  }
}
